type UnitName =
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

const MS_PER_SECOND = 1e3;
const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60;
const SECS_PER_DAY = SECS_PER_HOUR * 24;
const SECS_PER_WEEK = SECS_PER_DAY * 7;
const SECS_PER_YEAR = SECS_PER_DAY * 365.25;
const SECS_PER_MONTH = SECS_PER_YEAR / 12;

interface Unit {
  name: UnitName;
  seconds: number;
  threshold: number;
}

const UNITS: Unit[] = [
  {
    name: "second",
    seconds: 1,
    threshold: 50
  },
  {
    name: "minute",
    seconds: SECS_PER_MIN,
    threshold: 50
  },
  {
    name: "hour",
    seconds: SECS_PER_HOUR,
    threshold: 22
  },
  {
    name: "day",
    seconds: SECS_PER_DAY,
    threshold: 6
  },
  {
    name: "week",
    seconds: SECS_PER_WEEK,
    threshold: 4
  },
  {
    name: "month",
    seconds: SECS_PER_MONTH,
    threshold: 10
  },
  {
    name: "year",
    seconds: SECS_PER_YEAR,
    threshold: Number.POSITIVE_INFINITY
  }
];

// defaultUnit is never used, because everything < Number.POSITIVE_INFINITY
const defaultUnit: Unit = UNITS[0];

export default function selectUnit(
  from: Date | number,
  to: Date | number = Date.now()
): { value: number; unit: UnitName } {
  const secs = (+from - +to) / MS_PER_SECOND;

  let unit: Unit = UNITS.find(({ seconds, threshold }) => Math.abs(secs / seconds) < threshold) || defaultUnit;
  
  return {
    value: Math.round(secs / unit.seconds),
    unit: unit.name
  };
}
