import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";
import ReactMarkdown from "react-markdown";

import { boxType } from "types";
import { BoxActivationRate } from "components";

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const explanationMarkdown = `
Généralement le taux d'activation varie entre 0 et 100 %, mais peut très bien être supérieur à 100 %. Par exemple, si je viens d'être installé hier, et j'ai déjà payé 7 jours d'avance, mon taux sera de 700 %.

Le taux n'est donc pas plafonné à 100 %, cela permet par exemple d'identifier les clients qui paient régulièrement et avec de l'avance.

Pour le calcul du taux, on considère que l'âge minimal d'une installation est de 1 jour, pour éviter de diviser par zéro.
`;

class ActivationRateInfo extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {
    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T id="activationRate" />
          </DialogTitle>
          <DialogContent>
            <ReactMarkdown linkTarget="_blank" source={explanationMarkdown} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <span>
          <T id="activationRateInfo" />{" "}
          <strong>
            <BoxActivationRate box={box} />
          </strong>{" "}
          <IconButton fontSize="inherit" className={classes.helpButton} onClick={this.toggleDialog}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Fragment>
    );
  }
}

ActivationRateInfo.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(ActivationRateInfo);
