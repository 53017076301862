import PouchDB from "pouchdb";

export const localChangesOpts = {
  since: "now",
  live: true,
  include_docs: true,
};

export const roSelector = userId => ({
  $or: [
    { _id: { $regex: "^urn:qotto:installation:" } },
    {
      $and: [
        { _id: { $regex: "^urn[.:]qotto[.:]chat_message:" } },
        { "author.user_id": { $ne: userId } },
      ],
    },
  ],
});

export const rwSelector = userId => ({
  $or: [
    { _id: { $regex: "^urn[.:]qotto[.:]client_callback:" } },
    { _id: { $regex: "^urn[.:]qotto[.:]client_chat:" } },
    { _id: { $regex: "^urn[.:]qotto[.:]client_note:" } },
    { _id: { $regex: "^urn[.:]qotto[.:]last_client_call:" } },
    { _id: { $regex: "^urn[.:]qotto[.:]flag:" } },
    {
      $and: [
        { _id: { $regex: "^urn[.:]qotto[.:]chat_message:" } },
        { "author.user_id": { $eq: userId } },
      ],
    },
    {
      $and: [
        { _id: { $regex: "^urn[.:]qotto[.:]chat_subscription:" } },
        { user_id: { $eq: userId } },
      ],
    },
  ],
});

export const readWriteReplicationOpts = ({ userId, checkpoint = true }) => ({
  filter: "_selector",
  batch_size: 500,
  batch_limit: 10,
  selector: rwSelector(userId),
  checkpoint,
});

export const readWriteSyncOpts = ({ userId, checkpoint = true }) => ({
  ...readWriteReplicationOpts({ userId, checkpoint }),
  live: true,
  retry: true,
});

export const readOnlyLiveChangesOpts = {
  feed: "continuous",
  heartbeat: 10000,
  include_docs: true,
  filter: "_selector",
};

export const localDatabaseOpts = {
  revs_limit: 3,
  auto_compaction: true,
};

export const remoteDatabaseOpts = {
  fetch(url, opts) {
    opts.credentials = "include";
    return PouchDB.fetch(url, opts);
  },
  skip_setup: true,
};
