import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Popup } from "react-leaflet";

import { PopupContextForwarder } from "../";
import { CountryContext } from "contexts";

const styles = {};

class CustomPopup extends PureComponent {
  componentDidUpdate() {
    this.props.onInit(this.elt.leafletElement);
  }

  render() {
    const { classes, children, context, ...props } = this.props;

    return (
      <CountryContext.Consumer>
        {countryContext => (
          <Popup ref={elt => (this.elt = elt)} {...props}>
            <PopupContextForwarder context={context}>
              <CountryContext.Provider value={countryContext}>{children}</CountryContext.Provider>
            </PopupContextForwarder>
          </Popup>
        )}
      </CountryContext.Consumer>
    );
  }
}

CustomPopup.propTypes = {
  onInit: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomPopup);
