import React from "react";

import { UserContext } from "../";

const UTC_TIME_URL = process.env.REACT_APP_UTC_TIME_URL;
const OFFSET_INTERVAL_TIME = 30 * 60 * 1000; // 30 minutes;
const OFFSET_ALERT_THRESHOLD = 5 * 60 * 1000; // 5 minutes;
const OFFSET_ALERT_REPEAT = 30 * 24 * 60 * 60 * 1000; // 1 month;

const DateContext = React.createContext({
  offset: null,
});

export default DateContext;

const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const fetchOffset = () =>
  Promise.race([
    fetch(UTC_TIME_URL, {
      mode: "cors",
      cache: "no-store",
    })
      .then(response => {
        if (!response.ok) {
          throw Error("failed to fetch UTC time");
        }
        return response.text();
      })
      .then(isoString => Date.now() - new Date(isoString).getTime()),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), OFFSET_INTERVAL_TIME)
    ),
  ]);

class Provider extends React.Component {
  constructor(props) {
    super(props);

    const clientTimezone = getCurrentTimezone();
    const userTimezone = props.timezone;

    this.state = {
      timezone: userTimezone || clientTimezone,
      offset: parseInt(localStorage.getItem("offset"), 10) || 0,
      setTimezone: this.setTimezone,
      getDate: this.getDate,
      userTimezone,
      clientTimezone,
      openTzToast: false,
      onTzToastClose: this.onTzToastClose,
      openOffsetToast: false,
      onOffsetToastClose: this.onOffsetToastClose,
    };
  }

  updateOffset = async () => {
    const fetchStartTime = Date.now();
    fetchOffset().then(rawOffset => {
      const fetchEndTime = Date.now();
      const delay = fetchEndTime - fetchStartTime;
      const offset = Math.round(rawOffset - delay / 2);
      if (Math.abs(offset) > OFFSET_ALERT_THRESHOLD) {
        this.openOffsetToast();
        // TODO remove log once threshold issue has disappear
        console.log("above threshold offset", {
          fetchStartTime,
          fetchEndTime,
          rawOffset,
          offset,
          delay,
        });
      } else {
        this.closeOffsetToast();
        localStorage.removeItem("lastOffsetAlert");
      }
      this.setState({ offset });
      localStorage.setItem("offset", offset);
    }, console.warn);
  };

  onTzToastClose = () => {
    this.setState({
      openTzToast: false,
    });
  };

  openOffsetToast = () => {
    const lastAlertTime = parseInt(localStorage.getItem("lastOffsetAlert"), 10) || 0;
    const timeSinceLastAlert = Date.now() - lastAlertTime;
    const openOffsetToast = timeSinceLastAlert > OFFSET_ALERT_REPEAT;
    this.setState({ openOffsetToast });
  };

  closeOffsetToast = () => {
    this.setState({
      openOffsetToast: false,
    });
  };

  onOffsetToastClose = () => {
    this.closeOffsetToast();
    localStorage.setItem("lastOffsetAlert", Date.now());
  };

  updateTimezone = () => {
    const clientTimezone = getCurrentTimezone();
    if (clientTimezone !== this.state.clientTimezone) {
      const { userTimezone } = this.state;
      if (clientTimezone === userTimezone) {
        localStorage.removeItem("timezone");
        this.setState({
          timezone: clientTimezone,
          clientTimezone,
          userTimezone: null,
          openTzToast: true,
        });
      } else if (!userTimezone) {
        this.setState({
          timezone: clientTimezone,
          clientTimezone,
          openTzToast: true,
        });
      } else {
        this.setState({
          clientTimezone,
          openTzToast: true,
        });
      }
    }
  };

  getDate = () => {
    const { offset } = this.state;
    return new Date(Date.now() - offset);
  };

  setTimezone = userTimezone => {
    const timezone = userTimezone;
    const { clientTimezone } = this.state;
    if (timezone === clientTimezone) {
      this.props.setUserTimezone(null);
      this.setState({
        timezone,
        userTimezone: null,
      });
    } else {
      this.setState({ timezone, userTimezone });
      this.props.setUserTimezone(timezone);
    }
  };

  intervalFunc = () => {
    this.updateTimezone();
    this.updateOffset();
  };

  componentDidMount() {
    this.updateOffset();
    this.interval = setInterval(this.intervalFunc, OFFSET_INTERVAL_TIME);

    window.addEventListener("online", this.intervalFunc);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.intervalFunc);
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
  }

  render() {
    return <DateContext.Provider value={this.state}>{this.props.children}</DateContext.Provider>;
  }
}

const ProviderWrapper = props => (
  <UserContext.Consumer>
    {({ preferences, setPreference }) => (
      <Provider
        {...props}
        timezone={preferences.timezone}
        setUserTimezone={setPreference("timezone")}
      />
    )}
  </UserContext.Consumer>
);

export { ProviderWrapper as Provider };
