import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { FormattedMessage as T } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { PouchdbContext } from "contexts";

const PouchWarningMessage = ({ warningMessage }) => {
  switch (warningMessage) {
    case "USAGE_TOO_HIGH":
      return <T id="usageTooHigh" />;
    default:
      return warningMessage;
  }
};

const PouchDBEvents = () => (
  <PouchdbContext.Consumer>
    {({ warning, clearWarning }) => (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={clearWarning}
        open={Boolean(warning)}
        message={<PouchWarningMessage warningMessage={warning} />}
        action={
          <IconButton key="close" aria-label="Close" color="inherit" onClick={clearWarning}>
            <CloseIcon />
          </IconButton>
        }
      />
    )}
  </PouchdbContext.Consumer>
);

export default PouchDBEvents;
