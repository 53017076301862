import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage as T } from "react-intl";

import { SWContext } from "contexts";

const SWEvents = () => (
  <SWContext.Consumer>
    {({ newVersion, update, ignore }) => (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={newVersion}
        message={<T id="updateAvailable" />}
        action={[
          <Button key="undo" color="secondary" size="small" onClick={update}>
            <T id="reload" />
          </Button>,
          <IconButton key="close" aria-label="Close" color="inherit" onClick={ignore}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )}
  </SWContext.Consumer>
);

export default SWEvents;
