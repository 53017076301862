import { PureComponent } from "react";
import PropTypes from "prop-types";

class PopupContextForwarder extends PureComponent {
  getChildContext() {
    return this.props.context;
  }

  render() {
    return this.props.children;
  }
}

PopupContextForwarder.childContextTypes = {
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

PopupContextForwarder.propTypes = {
  context: PropTypes.object.isRequired,
};

export default PopupContextForwarder;
