import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import { externalServiceType } from "types";

const ExternalIconButton = ({ service, args, ...props }) => {
  const Icon = service.icon;

  return (
    <Tooltip title={service.label}>
      <IconButton
        href={service.uri(args)}
        target="_blank"
        style={{ color: service.color }}
        {...props}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
};

ExternalIconButton.propTypes = {
  service: externalServiceType.isRequired,
  args: PropTypes.object.isRequired,
};

export default ExternalIconButton;
