export default async function() {
  const isProd = process.env.NODE_ENV === "production";
  if (!isProd) {
    return true; // no multitabs handling in dev mode
  }
  if (!navigator.serviceWorker) {
    throw Error("SERVICEWORKER_NOT_SUPPORTED");
  }

  const controller = await new Promise(resolve => {
    const onActive = () => {
      window.removeEventListener("serviceworkeractive", onActive);
      resolve(navigator.serviceWorker.controller);
    };
    if (navigator.serviceWorker.controller) {
      return resolve(navigator.serviceWorker.controller);
    }
    window.addEventListener("serviceworkeractive", onActive);
  });

  return new Promise(resolve => {
    const onSWMessage = ({ data }) => {
      if (data.type === "MAIN_TAB_RESULT") {
        navigator.serviceWorker.removeEventListener("message", onSWMessage);
        const isMainTab = data.payload;
        resolve(isMainTab);
      }
    };

    navigator.serviceWorker.addEventListener("message", onSWMessage);
    controller.postMessage({ type: "MAIN_TAB_REQUEST" });
  });
}
