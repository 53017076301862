import React from "react";
import uuidv4 from "uuid/v4";
import { FormattedMessage as T } from "react-intl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/core/styles";

import { LoadingButton } from "components";

import { InstallationsContext, PouchdbContext } from "contexts";

const styles = {
  form: {
    position: "relative",
    marginTop: "8px",
    marginBottom: "24px",
  },
  inputLine: {
    display: "flex",
    alignItems: "flex-end",
  },
  dateInput: {
    minWidth: "203px",
  },
  submitLine: {
    marginTop: "8px",
  },
};

class ClientCallback extends React.PureComponent {
  state = {};

  static getDerivedStateFromProps = (props, state) => {
    const defaultCallbackDate = props.client.callback_date;
    if (defaultCallbackDate !== state.defaultCallbackDate) {
      return {
        defaultCallbackDate,
        callback_date: defaultCallbackDate,
        loading: false,
      };
    }
    return state;
  };

  handleChange = e => {
    this.setState({
      callback_date: e.target.value,
    });
  };

  cancelChanges = () => {
    this.setState({
      callback_date: this.state.defaultCallbackDate,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { client, callbackDoc, putDoc } = this.props;
    const { callback_date, defaultCallbackDate } = this.state;
    const hasChanged = callback_date !== defaultCallbackDate;
    let newDoc;
    if (callbackDoc) {
      newDoc = {
        ...callbackDoc,
        callback_date,
      };
    } else {
      newDoc = {
        _id: `urn.qotto.client_callback:${uuidv4()}`,
        type: "client_callback",
        client_id: client.zoho_id,
        callback_date,
      };
    }
    if (hasChanged) {
      this.setState({ loading: true });
      putDoc(newDoc);
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    const { callback_date, defaultCallbackDate, loading } = this.state;

    const hasChanged = callback_date !== defaultCallbackDate;

    const now = new Date().toISOString().substr(0, 10);

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <div className={classes.inputLine}>
          <TextField
            label={<T id="callbackDate" />}
            name="callbackDate"
            type="date"
            inputProps={{
              min: now,
            }}
            value={callback_date || ""}
            onChange={this.handleChange}
            className={classes.dateInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {hasChanged && (
          <div className={classes.submitLine}>
            <LoadingButton
              type="submit"
              size="small"
              loading={loading}
              disabled={loading}
              className={classes.saveButton}
              variant="contained"
              color="primary"
            >
              <SaveIcon /> <T id="save" />
            </LoadingButton>{" "}
            <Button size="small" disabled={loading} onClick={this.cancelChanges}>
              <T id="cancel" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

ClientCallback.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const ClientCallbackWrapper = props => (
  <InstallationsContext.Consumer>
    {({ clientCallbacks }) => (
      <PouchdbContext.Consumer>
        {({ putDoc }) => (
          <ClientCallback
            putDoc={putDoc}
            callbackDoc={clientCallbacks[props.client.zoho_id]}
            {...props}
          />
        )}
      </PouchdbContext.Consumer>
    )}
  </InstallationsContext.Consumer>
);

export default withStyles(styles)(ClientCallbackWrapper);
