import React, { useRef, useEffect } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const CustomPrimaryColor = ({ color, children }: { color: any; children: any }) => {
  const ref: any = useRef(null);
  let theme = ref.current;
  if (theme === null) {
    // before first render
    theme = createMuiTheme({
      palette: { primary: color },
      typography: {
        useNextVariants: true,
      },
    });
  }

  useEffect(() => {
    // persist theme for future renders
    ref.current = theme;
  }, []);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default CustomPrimaryColor;
