import React from "react";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core";

import icon from "./icon.svg";

const styles = {
  image: {
    width: "100%",
    height: "100%",
    backgroundImage: `url("${icon}")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};

const GoogleMaps = ({ classes, ...props }) => (
  <Icon {...props}>
    <div className={classes.image} />
  </Icon>
);

export default withStyles(styles)(GoogleMaps);
