import React from "react";
import { boxType } from "types";

const BoxBMSBrand = ({ box }) => {
  try{
    return (
      <span>
        <strong>{box.kit.bms.brand ? box.kit.bms.brand : "-"}</strong>
      </span>
    )
  }
  catch (TypeError){
    return (
      <span>
        <strong>-</strong>
      </span>
    )
  }
};
BoxBMSBrand.propTypes = {
  box: boxType.isRequired,
};
export default BoxBMSBrand;