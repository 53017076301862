import React from "react";
import { FormattedMessage as T } from "react-intl";

import { Boxes } from "./components";
import { CouchAuth } from "components";

// location is passed as a prop in order to trigger
// <Boxes /> render everytime '?search=' query param changes
const InstallationsScene = () => (
  <CouchAuth>
    <Boxes />
  </CouchAuth>
);

export default InstallationsScene;

//Breadcrumb component
export const Breadcrumb = () => <T id="installations" />;
