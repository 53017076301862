import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ClientChat, LastClientCall, ClientCallback } from "components";
import { PouchdbContext } from "contexts";
import { boxType } from "types";

const styles = {
  progressWrapper: {
    textAlign: "center",
  },
  progress: {
    margin: "100px 0",
  },
};

const CommentsTab = ({ classes, loading, box }) => (
  <div>
    {loading ? (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} />
      </div>
    ) : (
      <>
        <LastClientCall client={box.client} />
        <ClientCallback client={box.client} />
        <ClientChat client={box.client} />
      </>
    )}
  </div>
);

CommentsTab.propTypes = {
  box: boxType.isRequired,
};

const CommentsTabWrapper = props => (
  <PouchdbContext.Consumer>
    {({ readWriteLoading }) => <CommentsTab loading={readWriteLoading} {...props} />}
  </PouchdbContext.Consumer>
);

export default withStyles(styles)(CommentsTabWrapper);
