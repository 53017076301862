import CalendarClock from "mdi-material-ui/CalendarClock";
import CalendarCheck from "mdi-material-ui/CalendarCheck";
import CashMultiple from "mdi-material-ui/CashMultiple"
import { cyan, green, blue } from "@material-ui/core/colors";

export interface FlagDefinition {
  name: string;
  title: string;
  icon: any;
  color: any;
}

const contract_flags: FlagDefinition[] = [
  {
    name: "finished_soon",
    title: "contract-finished-soon",
    icon: CalendarClock,
    color: cyan,
  },
  {
    name: "fully_paid",
    title: "contract-fully-paid",
    icon: CashMultiple,
    color: green,
  },
  {
    name: "finished",
    title: "contract-finished",
    icon: CalendarCheck,
    color: blue,
  },
];

export default contract_flags;
