import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MapIcon from "@material-ui/icons/Map";
import { FormattedMessage as T, FormattedDate } from "react-intl";

import {
  ActivationRateInfo,
  AmountToRecoverInfo,
  WeeklyActivationsGridInfo,
  FollowupCategoryInfo,
  FinancialCategoryInfo,
  CheatScoreInfo,
  BoxStatusIcon,
  BoxTextStatus,
  LocalLink,
  OffsetFormattedRelative,
  ClientNote,
  InstallationFlags,
  ContractFlags,
  BoxRelativeExpiration,
} from "components";
import { isPriorToTreshold } from "data/installations/utils";
import { boxType } from "types";

import { ExternalLinks, CodeActivation, RefreshCplus, Receipt } from "../";

const styles = theme => ({
  root: {
    color: theme.palette.text.secondary,
    "& strong": {
      color: "initial",
      fontWeight: "initial",
    },
    "& p": {
      margin: "12px 0",
    },
  },
  statusIcon: {
    verticalAlign: "middle",
  },
});

const RelativeDateInfo = ({ label, timestamp }) => (
  <>
    {label}{" "}
    <strong>
      <FormattedDate
        value={new Date(timestamp)}
        year="numeric"
        month="long"
        day="2-digit"
        hour="2-digit"
        minute="2-digit"
      />{" "}
      (<OffsetFormattedRelative value={new Date(timestamp)} />)
    </strong>
  </>
);

const InfoTab = ({ box, classes }) => {
  const geolocation = box.gps_coordinates
    ? [box.gps_coordinates.lat, box.gps_coordinates.lon]
    : null;

  return (
    <div className={classes.root}>
      <p>
        <T id="flags" /> <InstallationFlags contractCode={box.contract.code} />
      </p>
      <p>
        <T id="contract-flags" />
          <ContractFlags
            contractCode={box.contract.code}
            finished_soon={box.contract.finished_soon}
            fully_paid={box.contract.fully_paid}
            finished={box.contract.finished}
          />
      </p>
      {
        box.kit ? (
          <p>
            Kit {box.kit.bms.brand} {box.kit.bms.model}{box.kit.canalplus_decoder ? ' avec décodeur Canal+': ''}.
            { (box.kit.bms)
              ? <span><br/>N° de série BMS : <code>{box.kit.bms.serial_number}</code></span>
              : ''
            }
            { box.kit.bms.smartcard
              ? <span><br/>N° de série Smartcard : <code>{box.kit.bms.smartcard.serial_number}</code></span>
              : ''
            }
            { box.kit.canalplus_decoder
              ? <span><br/>N° de série décodeur Canal+: <code>{box.kit.canalplus_decoder.serial_number}</code></span>
              : ''
            }
          </p>
        ) : ''
      }
      {box.expiration_timestamp && (
        <p>
          <BoxStatusIcon className={classes.statusIcon} box={box} />{" "}
          {box.contract.fully_paid ?
          <RelativeDateInfo
            label={<T id={"endOfContract"}/>}
            timestamp={box.expiration_timestamp}
          />
          :
          <RelativeDateInfo
            label={<BoxTextStatus expiration={box.expiration_timestamp} determinant />}
            timestamp={box.expiration_timestamp}
          />
          }
        </p>
      )}
      {!isPriorToTreshold(box.installation_date) && (
        <p>
          <RelativeDateInfo
            label={<T id="installationDatePrefix" />}
            timestamp={box.installation_date}
          />
        </p>
      )}
      {box.first_activation_date && (
        <p>
          <RelativeDateInfo
            label={<T id="firstActivatedPrefix" />}
            timestamp={box.first_activation_date}
          />
        </p>
      )}
      {box.box_associated_date && box.box_associated_date !== box.installation_date && (
        <p>
          <RelativeDateInfo
            label={<T id="boxInstalledPrefix" />}
            timestamp={box.box_associated_date}
          />
        </p>
      )}
      {box.credit_info && (
        <p>
          <ActivationRateInfo box={box} />
        </p>
      )}
      {box.activations_summary !== null &&
        box.activations_summary !== undefined &&
        box.first_activation_date !== null && <WeeklyActivationsGridInfo box={box} />}
      {box.credit_info && (
        <>
          <p>
            <FollowupCategoryInfo box={box} />
          </p>
          <p>
            <FinancialCategoryInfo box={box} />
          </p>
          {box.credit_info.amount_to_recover && (
            <p>
              <AmountToRecoverInfo box={box} />
            </p>
          )}
        </>
      )}
      {box.last_communication_timestamp && (
        <p>
          <RelativeDateInfo
            label={<T id="lastCommunicationPrefix" />}
            timestamp={box.last_communication_timestamp}
          />
        </p>
      )}
      {box.cheat_score !== null && box.cheat_score !== undefined && (
        <p>
          <CheatScoreInfo box={box} />
        </p>
      )}
      <div>
        <Button
          disabled={!geolocation}
          component={LocalLink}
          to={`/map/?box=${box.installation_id}`}
          color="primary"
        >
          <MapIcon /> <T id="map" />
        </Button>
        <CodeActivation box={box} />
        <RefreshCplus box={box} />
        <Receipt box={box} />
        <ExternalLinks box={box} />
      </div>
      <ClientNote client={box.client} />
    </div>
  );
};

InfoTab.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(InfoTab);
