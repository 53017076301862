import React, { PureComponent, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { FormattedMessage as T } from "react-intl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialpadIcon from "@material-ui/icons/Dialpad";

import { boxType } from "types";
import { CopyToClipboardButton } from "components";

const BACKEND_API_ENDPOINT = process.env.REACT_APP_BACKEND_API_ENDPOINT;
const SOLARIS_SERIAL_NB_REGEX = /^\d{4}$/;

const styles = {
  error: {
    color: "red",
  },
};

const CodeActivationError = ({ error }) => {
  switch (error) {
    case "API error (INVALID_REQUEST_FORMAT)": //400 response from the backend
      return <T id="invalidRequest"/>;
    case "API error (INVALID_REQUEST_FORMAT_SOLARIS)": //400 response from the backend
      return <T id="invalidRequestSolaris"/>;
    case "API error (COULD_NOT_AUTHENTICATE)": //401 response from the backend
      return <T id="invalidAuth"/>;
    case "API error (DOES_NOT_EXIST)": //404 response from the backend
      return <T id="bmsDoesntExist" />;
    case "API error (BACKEND_ERROR)": //503 response from the backend
      return <T id="deviceServerDoesntRespond"/>;
    default:
      return error;
  }
};

class CodeActivation extends PureComponent {
  state = {
    dialogOpen: false,
    loading: false,
    activationCode: null,
    requestCode: "",
    error: null,
  };

  toggleDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  };

  handleChange = name => e => {
    this.setState({
      [name]: e.target.value,
    });
  };

  needs_user_code = bms => {
    if (bms.brand === 'Victron' && SOLARIS_SERIAL_NB_REGEX.test(bms.serial_number)) {
      return bms.protocol_version !== '2'
    }
    return false
  }

  onSolarisRequestCode = () => e => {
    e.preventDefault();
    const { requestCode: code } = this.state;
    const { box } = this.props;

    this.setState({
      loading: true,
      error: null,
      activationCode: null,
    });
    let request_url = (`${BACKEND_API_ENDPOINT}/contract/${box.contract.code}/solaris-bms`);
    fetch(`${request_url}/${box.kit.bms.serial_number}/activation_code?`+ new URLSearchParams({user_code: code}), {
      method: "GET",
    })
      .then(res => {
        if (res.status !== 200) {
          return res.text().then(error => {
            switch (res.status){
              case 400:
                throw Error("API error (INVALID_REQUEST_FORMAT_SOLARIS)");
              case 401:
                throw Error("API error (COULD_NOT_AUTHENTICATE)");
              case 404:
                throw Error("API error (DOES_NOT_EXIST)");
              case 503:
                throw Error("API error (BACKEND_ERROR)");
              default:
                throw Error(error);
            }
          });
        } else {
          return res;
        }
      })
      .then(res => res.json())
      .then(({ activationCode }) => {
        this.setState({ activationCode, loading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message || String(error),
          loading: false,
        });
      });
      return false;
  };

  onRequestCode = () => e => {
    e.preventDefault();
    const { box } = this.props;

    this.setState({
      loading: true,
      error: null,
      activationCode: null,
      dialogOpen: !this.state.dialogOpen,
    });
    var request_url;
    if (box.kit.bms.brand ==='Victron'){
      request_url = (
        `${BACKEND_API_ENDPOINT}/contract/${box.contract.code}/victron-bms/${box.kit.bms.serial_number}/activation_code`
        );
    }
    else if (box.kit.bms.brand ==='Biolite'){
      request_url = (
        `${BACKEND_API_ENDPOINT}/contract/${box.contract.code}/biolite-bms/${box.kit.bms.serial_number}/activation_code`
        );
    }
    fetch(request_url, {
      method: "GET",
    })
      .then(res => {
        if (res.status !== 200) {
          return res.text().then(error => {
            switch (res.status){
              case 400:
                throw Error("API error (INVALID_REQUEST_FORMAT)");
              case 401:
                throw Error("API error (COULD_NOT_AUTHENTICATE)");
              case 404:
                throw Error("API error (DOES_NOT_EXIST)");
              case 503:
                throw Error("API error (BACKEND_ERROR)");
              default:
                throw Error(error);
            }
          });
        } else {
          return res;
        }
      })
      .then(res => res.json())
      .then(({ activationCode }) => {
        this.setState({ activationCode, loading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message || String(error),
          loading: false,
        });
      });
    return false;
  }
  render() {
    const { classes, box } = this.props;
    const { dialogOpen, requestCode, activationCode, error, loading } = this.state;
    try {
      // TODO this variable needs to return to its original form, as soon as the data is available for all kits
      //const enable = box.expiration_timestamp && box.expiration_timestamp > Date.now();
      var enable = typeof(box.kit.bms.brand)==='undefined' ? false : true ;
    } catch (TypeError) {
      return (
        <Fragment>
          <Button color="primary" onClick={this.toggleDialog} disabled={false}>
            <DialpadIcon /> <T id="codeActivation" />
          </Button>
        </Fragment>
      );
    }
    if (this.needs_user_code(box.kit.bms)){
      return (
        <Fragment>
          <Button color="primary" onClick={this.toggleDialog} disabled={!enable}>
            <DialpadIcon /> <T id="codeActivation" />
          </Button>
          <Dialog onClose={this.toggleDialog} open={dialogOpen}>
            <DialogTitle>
              <T id="codeActivation" />
            </DialogTitle>
            <DialogContent>
              {
                <form onSubmit={this.onSolarisRequestCode()}>
                  <TextField
                    autoFocus
                    name="requestCode"
                    value={requestCode}
                    autoComplete="off"
                    onChange={this.handleChange("requestCode")}
                    label={<T id="requestCode" />}
                    required
                  />
                  <Button type="submit" color="primary" disabled={!requestCode || loading}>
                    <T id="send" />
                  </Button>
                  {loading && <LinearProgress />}
                  {activationCode && (
                    <p>
                      <T id="activationCode" />: <b>{activationCode}</b>
                      <CopyToClipboardButton text={activationCode} />
                    </p>
                  )}
                  {error && (
                    <p className={classes.error}>
                      <CodeActivationError error={error} />
                    </p>
                  )}
                </form>
              }
            </DialogContent>
          </Dialog>
        </Fragment>
      );
    }
    else{
      return(
        <Fragment>
            <Button color="primary" onClick={this.onRequestCode()} disabled={!enable || loading}>
              <DialpadIcon /> <T id="generateCode" />
            </Button>
            <Dialog onClose={this.toggleDialog} open={dialogOpen}>
              <DialogTitle>
                <T id="generateCode" />
              </DialogTitle>
              <DialogContent>
                {loading && <LinearProgress />}
                {activationCode && (
                  <p>
                    <T id="activationCode" />: <b>{activationCode}</b>
                    <CopyToClipboardButton text={activationCode} />
                  </p>
                )}
                {error && (
                  <p className={classes.error}>
                    <CodeActivationError error={error} />
                  </p>
                )}
              </DialogContent>
            </Dialog>
          </Fragment>
      );
    }
  }
}

CodeActivation.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(CodeActivation);
