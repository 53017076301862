import React from "react";
import selectUnit from "./selectUnit";
import { FormattedRelativeTime } from "react-intl";

// This component is made solely for compatibility with the component FormattedRelative which was removed from react-intl v3.
// See https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#formattedrelativetime
const FormattedRelative = ({ value, ...props }) => {
  const { value: deltaValue, unit } = selectUnit(value);
  return <FormattedRelativeTime {...props} value={deltaValue} unit={unit} />;
};

export default FormattedRelative;
