import React, { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage as T } from "react-intl";

import { BoxDateTime } from "components";
import { boxType } from "types";

const BoxActivationRate = ({ box }) => {
  if (!box.credit_info) {
    return null;
  }
  const activationRate = box.credit_info.activation_rate;
  return (
    <Tooltip
      title={
        <Fragment>
          <T id="from" /> <BoxDateTime value={box.installation_date} />
        </Fragment>
      }
    >
      <span>{Math.round(activationRate * 100)}%</span>
    </Tooltip>
  );
};

BoxActivationRate.propTypes = {
  box: boxType.isRequired,
};

export default BoxActivationRate;
