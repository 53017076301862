import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import { FormattedMessage as T, injectIntl } from "react-intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import queryString from "query-string";
import DocumentTitle from "react-document-title";

import { BoxClient, LocalLink } from "components";
import { boxType } from "types";
import { zohoClient, zohoContract, zohoInstallation } from "utils/externalServices";

import { InfoTab, ZohoTab, CommentsTab, TelemetryTab } from "../";

const styles = theme => ({
  card: {
    margin: "8px",
  },
  contractCode: {
    color: theme.palette.text.secondary,
  },
});

const defaultTab = "infos";

const tabValues = {
  infos: 0,
  comments: 1,
  kibana: 2,
  zoho_client: 3,
  zoho_contract: 4,
  zoho_installation: 5,
};

class Installation extends React.PureComponent {
  render() {
    const { box, location, classes } = this.props;

    const t = this.props.intl.formatMessage;
    const search = queryString.parse(location.search);
    const tab = search.show || defaultTab;

    return (
      <Card className={classes.card}>
        <DocumentTitle
          title={`${t({ id: "installationBreadcrumb" }, box.contract)} - ${t({
            id: "appTitle",
          })}`}
        />
        <CardHeader
          title={
            box.kit
              ? (
                <span>
                  <BoxClient box={box} />
                  {" - Contrat #" + box.contract.code + " "}
                  <span className={classes.contractCode}>
                    ({box.kit.bms.brand}{box.kit.canalplus_decoder ? ' avec Canal+': ''})
                  </span>
                </span>
              )
              : (
                <span>
                  <BoxClient box={box} />
                  {" - Contrat #" + box.contract.code + " "}
                  <span className={classes.contractCode}>
                    (BMS {box.bms_label}{"/"}{box.sc_label})
                  </span>
                </span>
              )
          }
        />
        <Tabs
          value={tabValues[tab]}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={<T id="showInfos" />} component={LocalLink} to={location.pathname} />
          <Tab
            label={<T id="showComments" />}
            component={LocalLink}
            to={location.pathname + "?show=comments"}
          />
          <Tab
            label={<T id="showKibana" />}
            component={LocalLink}
            to={location.pathname + "?show=kibana"}
          />
          <Tab
            label={<T id="showZohoClient" />}
            component={LocalLink}
            to={location.pathname + "?show=zoho_client"}
          />
          <Tab
            label={<T id="showZohoContract" />}
            component={LocalLink}
            to={location.pathname + "?show=zoho_contract"}
          />
          {box.zoho_id && (
            <Tab
              label={<T id="showZohoInstallation" />}
              component={LocalLink}
              to={location.pathname + "?show=zoho_installation"}
            />
          )}
        </Tabs>
        <Divider />
        <CardContent>
          {tab === "infos" ? (
            <InfoTab box={box} />
          ) : tab === "comments" ? (
            <CommentsTab box={box} />
          ) : tab === "kibana" ? (
            <TelemetryTab box={box} />
          ) : tab === "zoho_client" ? (
            <ZohoTab
              zohoService={zohoClient}
              zohoArgs={{
                id: box.client.zoho_id,
                project: box.zoho_project,
              }}
            />
          ) : tab === "zoho_contract" ? (
            <ZohoTab
              zohoService={zohoContract}
              zohoArgs={{
                id: box.contract.zoho_id,
                project: box.zoho_project,
              }}
            />
          ) : tab === "zoho_installation" && box.zoho_id ? (
            <ZohoTab
              zohoService={zohoInstallation}
              zohoArgs={{
                id: box.zoho_id,
                project: box.zoho_project,
              }}
            />
          ) : null}
        </CardContent>
      </Card>
    );
  }
}

Installation.propTypes = {
  box: boxType.isRequired,
};

export default compose(withStyles(styles), injectIntl, withRouter)(Installation);
