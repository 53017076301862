import { formatDatetime } from "utils/format";

const G_T_OPERATOR = "gt";
const L_T_OPERATOR = "lt";
const EQUALS_OPERATOR = "equals";
const CONTAINS_OPERATOR = "contains";
const NULL_OPERATOR = "isNull";
const NOT_NULL_OPERATOR = "isNotNull";

const parseDate = dateString => {
  const [date, time] = dateString.split(" ");
  const [year, month, day] = date.split("-").map(val => parseInt(val, 0));
  const [hours, minutes, seconds] = (time || "").split(":").map(val => parseInt(val, 0));
  return new Date(year || 0, month - 1 || 0, day || 1, hours || 0, minutes || 0, seconds || 0);
};

const gt = (value, ref) => value !== null && value > ref;

const lt = (value, ref) => value !== null && value < ref;

const genericNumber = parse => ({
  [G_T_OPERATOR]: (filterTerm, value) => gt(value, parse(filterTerm)),
  [L_T_OPERATOR]: (filterTerm, value) => lt(value, parse(filterTerm)),
  [NULL_OPERATOR]: (_, value) => value === null,
  [NOT_NULL_OPERATOR]: (_, value) => value !== null,
});

export const number = genericNumber(parseFloat);

export const integer = genericNumber(parseInt);

export const date = {
  [CONTAINS_OPERATOR]: (filterTerm, value) => {
    const date = value;
    if (!date) return false;
    return formatDatetime(date).indexOf(filterTerm) !== -1;
  },
  ...genericNumber(parseDate),
};

export const equals = {
  [EQUALS_OPERATOR]: (filterTerm, value) => filterTerm === value,
  [NULL_OPERATOR]: (_, value) => value === null,
  [NOT_NULL_OPERATOR]: (_, value) => value !== null,
};

export const range = {
  ...equals,
  [G_T_OPERATOR]: (filterTerm, value) => gt(value, filterTerm),
  [L_T_OPERATOR]: (filterTerm, value) => lt(value, filterTerm),
};
