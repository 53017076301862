import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import seedrandom from "seedrandom";
import Linkify from "react-linkify";

import { CustomFormattedDate } from "components";

const getMessageTimestamp = message =>
  message.date_synced || message.date_typed || message.timestamp;

const styles = {
  messageContent: {
    paddingLeft: "12px",
    position: "relative",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    "&:hover $messageButtons": {
      visibility: "visible",
    },
  },
  messageButtons: {
    visibility: "hidden",
    position: "absolute",
    display: "inline",
    right: "8px",
    top: 0,
  },
  messageButton: {
    fontSize: "inherit",
    padding: "0",
    opacity: 0.5,
  },
  messageHeader: {
    marginTop: "24px",
    paddingBottom: "2px",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const authorColor = userId => `hsl(${Math.floor(new seedrandom(userId)() * 360)}, 80%, 35%)`;

const isItWrittenAtTheSameTime = (messageA, messageB) =>
  messageA.author.user_id === messageB.author.user_id &&
  Math.abs(getMessageTimestamp(messageA) - getMessageTimestamp(messageB)) < 60 * 1000;

const ChatMessage = ({
  message,
  classes,
  lastMessage,
  selected,
  location,
  canDelete,
  onDelete,
}) => {
  const { content, author, _memory_only } = message;

  const timestamp = getMessageTimestamp(message);

  const hideHeader = lastMessage && isItWrittenAtTheSameTime(message, lastMessage);

  const id = message.message_id;
  const messageLocation = {
    ...location,
    hash: selected ? "" : `#${id}`,
  };

  return (
    <div id={id}>
      {!hideHeader && (
        <Typography
          color="textSecondary"
          className={classes.messageHeader}
          style={{
            backgroundColor: selected ? "rgba(255, 255, 134, 0.5)" : "initial",
            opacity: _memory_only ? 0.5 : 1,
          }}
        >
          <strong style={{ color: authorColor(author.user_id) }}>{author.displayed_name}</strong>,{" "}
          <Link to={messageLocation} replace className={classes.link}>
            <CustomFormattedDate value={new Date(timestamp)} />
          </Link>
        </Typography>
      )}
      <Typography
        className={classes.messageContent}
        style={{
          backgroundColor: selected ? "rgba(255, 255, 134, 0.5)" : undefined,
          opacity: _memory_only ? 0.5 : 1,
        }}
      >
        {!_memory_only && (
          <span className={classes.messageButtons}>
            <IconButton
              component={Link}
              to={messageLocation}
              replace
              className={classes.messageButton}
            >
              {selected ? <LinkOffIcon /> : <LinkIcon />}
            </IconButton>
            &nbsp;
            {canDelete && (
              <Tooltip title={<T id="delete" />}>
                <IconButton className={classes.messageButton} onClick={onDelete}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </span>
        )}
        <Linkify properties={{ target: "_blank" }}>
          {content.split("\n").map((line, index) => (
            <Fragment key={index}>
              {index !== 0 && <br />}
              {line}
            </Fragment>
          ))}
        </Linkify>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ChatMessage);
