import React from "react";

import { SWEvents, PouchDBEvents, DateEvents } from "./components";

const Events = () => (
  <React.Fragment>
    <SWEvents />
    <PouchDBEvents />
    <DateEvents />
  </React.Fragment>
);

export default Events;
