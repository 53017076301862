import React from "react";

import { formatWh } from "utils/format";
import { boxType } from "types";

const BoxDailyConsumption = ({ box }) => {
  const energy = box.avg_power_7days;
  return <span>{energy && formatWh(energy.consumption)}</span>;
};

BoxDailyConsumption.propTypes = {
  box: boxType.isRequired,
};

export default BoxDailyConsumption;
