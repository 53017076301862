import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudSyncIcon from "mdi-material-ui/CloudSync";

const styles = theme => ({
  badge: {
    animationName: "syncRotate",
    animationDuration: "0.8s",
    animationDirection: "alternate",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in",
  },
  icon: {
    color: "white",
    fontSize: "18px",
  },
  noNetworkIcon: {
    fontSize: "16px",
  },
  "@keyframes syncRotate": {
    from: { filter: "grayscale(0%)", opacity: 1 },
    to: { filter: "grayscale(70%)", opacity: 0.7 },
  },
});

const SyncIcon = ({ rwSyncing, roSyncing, ...props }) => {
  let Icon = null;
  if (rwSyncing) {
    Icon = CloudSyncIcon;
  } else if (roSyncing) {
    Icon = CloudDownloadIcon;
  }

  return <Icon {...props} />;
};

class StatusBadge extends React.PureComponent {
  state = {
    online: navigator && navigator.onLine,
  };

  componentDidMount() {
    if (window) {
      window.addEventListener("online", this.handleOnline);
      window.addEventListener("offline", this.handleOffline);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("online", this.handleOnline);
      window.removeEventListener("offline", this.handleOffline);
    }
  }

  handleOffline = e => {
    this.setState({
      online: false,
    });
  };

  handleOnline = e => {
    this.setState({
      online: true,
    });
  };

  render() {
    const { roSyncing, rwSyncing, children, classes } = this.props;
    const { online } = this.state;

    const activeSync = roSyncing || rwSyncing;

    if (!online) {
      return (
        <Badge badgeContent={<SignalWifiOffIcon className={classes.noNetworkIcon} />} color="error">
          {children}
        </Badge>
      );
    } else if (activeSync) {
      return (
        <Badge
          className={classes.badge}
          badgeContent={
            <SyncIcon
              rwSyncing={rwSyncing}
              roSyncing={roSyncing}
              fontSize="inherit"
              className={classes.icon}
            />
          }
          color="secondary"
        >
          {children}
        </Badge>
      );
    } else {
      return children;
    }
  }
}

export default withStyles(styles)(StatusBadge);
