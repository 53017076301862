const BACKEND_API_ENDPOINT = process.env.REACT_APP_BACKEND_API_ENDPOINT;
const REMOTE_SERVER = process.env.REACT_APP_COUCHDB_SERVER;

const safeFetch = (...args) =>
  fetch(...args).then(response => {
    const { ok, status } = response;
    if (!ok) {
      throw Error(`Request failed with status ${status}`);
    }
    return response;
  });

const logIn = (name, password) =>
  safeFetch(REMOTE_SERVER + "_session", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, password }),
  });

const logOut = () =>
  safeFetch(REMOTE_SERVER + "_session", {
    method: "DELETE",
    credentials: "include",
  });

const getSession = () =>
  safeFetch(REMOTE_SERVER + "_session", {
    credentials: "include",
  }).then(_ => _.json());

const getUser = userId =>
  safeFetch(`${REMOTE_SERVER}_users/org.couchdb.user:${userId}`, {
    credentials: "include",
  }).then(_ => _.json());

const fetchCouchDBCredentials = () =>
  safeFetch(BACKEND_API_ENDPOINT + "/couchdb-credentials", {
    credentials: "include",
  })
    .then(response => response.json())
    .catch(() => null);

const getCouchDBCredentials = async database => {
  const credentials = await fetchCouchDBCredentials();
  if (credentials) {
    const localCredentials = await database.get("_local/credentials").catch(_ => null);
    if (!localCredentials) {
      await database.put({
        _id: "_local/credentials",
        ...credentials,
      });
    }
  }
  return credentials;
};

const authenticate = async (userData, database) => {
    if (!userData || userData.applications.indexOf("Dashboard") === -1) {
    throw new Error("Cannot authenticate to dashboard");
  }
    const credentials = await getCouchDBCredentials(database);
  if (credentials) {
    const { username, password } = credentials;
    await logIn(username, password);
  }
};

const verifyAuthData = authData => {
  if (!authData) return false;
  if (!authData["device-server-token"]) return false;
  return true;
};

const getAuthInfoFromRemote = async () => {
  const { userCtx: session } = await getSession();
  if (!session.name) return null;
  const user = await getUser(session.name);
  if (!verifyAuthData(user.auth_data)) return null;
  /*
      'user' contains user info as provided by couch-manager.
      It is used by CodeActivation to get device-server-token.
    */
  /*
      'session' contains informations regarding user's session, as provided by couchdb.
    */
  return { user, session };
};

const getAuthInfo = async database => {
  const auth = await getAuthInfoFromRemote().catch(() => database.get("_local/auth"));
  if (!auth) throw new Error("Invalid authentication");
  const localAuth = await database.get("_local/auth").catch(() => ({
    _id: "_local/auth",
  }));

  await database.put({ ...localAuth, ...auth });
  return auth;
};

export { authenticate, getAuthInfo, logOut };
