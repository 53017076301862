import React from "react";
import queryString from "query-string";
import { withRouter, matchPath } from "react-router-dom";

import { PouchdbContext, CountryContext, UserContext } from "../";

import { filter, sort } from "utils/filter";
import boxProperties, { defaultProperty } from "data/installations/properties";

const InstallationsContext = React.createContext();

export default InstallationsContext;

const addLastClientCall = lastClientCalls => installation => {
  const lastClientCall = lastClientCalls[installation.client.zoho_id];
  return {
    ...installation,
    client: {
      ...installation.client,
      last_call: lastClientCall ? lastClientCall.date_called : null,
    },
  };
};

const migrateClientCallback = callback_date => {
  if (callback_date && !isNaN(callback_date)) {
    return new Date(callback_date).toISOString().substr(0, 10);
  }
  return callback_date;
};

const addClientCallback = clientCallbacks => installation => {
  const clientCallback = clientCallbacks[installation.client.zoho_id];
  return {
    ...installation,
    client: {
      ...installation.client,
      callback_date: clientCallback ? migrateClientCallback(clientCallback.callback_date) : null,
    },
  };
};

const boxFilter = filter(boxProperties, defaultProperty);

const boxSort = sort(boxProperties);

const boxCountryFilter = country => installation =>
  boxProperties.country.operator.equals(
    country.label,
    boxProperties.country.getValue(installation)
  );

const countryFilter = filterCountry => installations =>
  filterCountry ? installations.filter(boxCountryFilter(filterCountry)) : installations;

const installationMatch = ({ pathname }) =>
  matchPath(pathname, {
    path: "/:country?/",
    exact: true,
  }) ||
  matchPath(pathname, {
    path: "/:country?/map",
    exact: true,
  });

const typeToCollection = type => {
  switch (type) {
    case "chat_message":
      return "messages";
    case "client_chat":
      return "clientChats";
    case "chat_subscription":
      return "chatSubscriptions";
    case "client_note":
      return "notes";
    case "last_client_call":
      return "lastClientCalls";
    case "client_callback":
      return "clientCallbacks";
    case "installation":
      return "installations";
    case "flag":
      return "flags";
    default:
      return null;
  }
};

const reduceBy = (array, lambda) => {
  const res = {};
  for (const elt of array) {
    res[lambda(elt)] = elt;
  }
  return res;
};

const getContractCodeFromFlagDoc = flagDoc => {
  const contractCode = flagDoc._id.split(":")[2];
  return contractCode;
};

const getFlagNameFromFlagDoc = flagDoc => {
  const flagName = flagDoc._id.split(":")[3];
  return flagName;
};

const reduceFlagsByContractCode = flags => {
  const res = {};
  for (const flagDoc of flags) {
    const contractCode = getContractCodeFromFlagDoc(flagDoc);
    if (!res[contractCode]) {
      res[contractCode] = [];
    }
    res[contractCode].push(flagDoc);
  }
  return res;
};

const addFlags = flagsByContractCode => installation => {
  const flagDocs = flagsByContractCode[installation.contract.code] || [];
  return {
    ...installation,
    flags: flagDocs.reduce(
      (flags, flagDoc) => ({
        ...flags,
        [getFlagNameFromFlagDoc(flagDoc)]: true,
      }),
      {}
    ),
  };
};

const getDerivedStateFromDocs = (docs, state) => {
  const { filterCountry, sortProperty, order, filterInput } = state;
  const collections = {
    messages: [],
    clientChats: [],
    chatSubscriptions: [],
    notes: [],
    lastClientCalls: [],
    clientCallbacks: [],
    installations: [],
    flags: [],
  };
  Object.values(docs).forEach(doc => {
    if (!doc || doc._deleted || !doc.type) return;
    const collection = collections[typeToCollection(doc.type)];
    if (collection) {
      collection.push(doc);
    }
  });

  collections.lastClientCalls = reduceBy(collections.lastClientCalls, _ => _.client_id);
  collections.clientCallbacks = reduceBy(collections.clientCallbacks, _ => _.client_id);
  collections.flags = reduceFlagsByContractCode(collections.flags);
  collections.installations = collections.installations
    .map(addLastClientCall(collections.lastClientCalls))
    .map(addClientCallback(collections.clientCallbacks))
    .map(addFlags(collections.flags));
  const installationsById = reduceBy(collections.installations, _ => _.installation_id);
  const localizedInstallations = countryFilter(filterCountry)(collections.installations);
  const sortedInstallations = localizedInstallations.sort(boxSort(sortProperty, order));
  const filteredInstallations = sortedInstallations.filter(boxFilter(filterInput));
  return {
    ...collections,
    installationsById,
    localizedInstallations,
    sortedInstallations,
    filteredInstallations,
  };
};

const getDerivedStateFromLocation = (location, filterCountry, state) => {
  const searchObject = queryString.parse(location.search);
  const sortProperty = searchObject.sort || state.sortProperty;
  const order = searchObject.order || state.order;
  const filterInput = searchObject.search || "";
  if (filterCountry !== state.filterCountry) {
    const localizedInstallations = countryFilter(filterCountry)(state.installations);
    const sortedInstallations = localizedInstallations.sort(boxSort(sortProperty, order));
    const filteredInstallations = sortedInstallations.filter(boxFilter(filterInput));
    return {
      order,
      filterInput,
      sortProperty,
      filterCountry,
      localizedInstallations,
      sortedInstallations,
      filteredInstallations,
    };
  } else if (sortProperty !== state.sortProperty) {
    const sortedInstallations = state.localizedInstallations.sort(boxSort(sortProperty, order));
    const filteredInstallations = sortedInstallations.filter(boxFilter(filterInput));
    const columns =
      state.columns.indexOf(sortProperty) > -1 ? state.columns : [...state.columns, sortProperty];
    return {
      order,
      columns,
      filterInput,
      sortProperty,
      sortedInstallations,
      filteredInstallations,
    };
  } else if (order !== state.order) {
    const sortedInstallations = state.sortedInstallations.slice().reverse();
    const filteredInstallations = state.filteredInstallations.slice().reverse();
    return {
      order,
      filterInput,
      sortedInstallations,
      filteredInstallations,
    };
  } else if (filterInput !== state.filterInput) {
    const filteredInstallations = state.sortedInstallations.filter(boxFilter(filterInput));
    return {
      filterInput,
      filteredInstallations,
    };
  }
};

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "asc",
      sortProperty: "expiration",
      columns: props.preferedColumns,
      filterInput: "",
      filterCountry: null,
      messages: [],
      clientChats: [],
      chatSubscriptions: [],
      lastClientCalls: {},
      clientCallbacks: {},
      notes: [],
      installations: [],
      installationsById: {},
      localizedInstallations: [],
      sortedInstallations: [],
      filteredInstallations: [],
      lastProps: {},
      setColumns: this.setColumns,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    const { lastProps } = state;
    let newState = {
      ...state,
      lastProps: props,
    };
    if (props.docs !== lastProps.docs) {
      //docs changed, equivalent to POUCHDB_GET
      const { docs } = props;
      newState = {
        ...newState,
        ...getDerivedStateFromDocs(docs, newState),
      };
    }

    if (props.location !== lastProps.location && installationMatch(props.location)) {
      //location changed, equivalent to @@router/LOCATION_CHANGE
      const { location, country } = props;
      newState = {
        ...newState,
        ...getDerivedStateFromLocation(location, country, newState),
      };
    }
    return newState;
  };

  setColumns = columns => {
    this.setState({ columns });
    this.props.setColumns(columns);
  };

  render() {
    return (
      <InstallationsContext.Provider value={this.state}>
        {this.props.children}
      </InstallationsContext.Provider>
    );
  }
}

Provider = withRouter(Provider);

const ProviderWrapper = props => (
  <UserContext.Consumer>
    {({ preferences, setPreference }) => (
      <PouchdbContext.Consumer>
        {({ docs }) => (
          <CountryContext.Consumer>
            {country => (
              <Provider
                {...props}
                preferedColumns={preferences.preferedColumns}
                setColumns={setPreference("preferedColumns")}
                docs={docs}
                country={country}
              />
            )}
          </CountryContext.Consumer>
        )}
      </PouchdbContext.Consumer>
    )}
  </UserContext.Consumer>
);

export { ProviderWrapper as Provider };
