import React from "react";

import boxProperties from "data/installations/properties";
import { FormattedDuration } from "components";

const BoxInstallationDays = ({ box }) => {
  const installationDate = boxProperties.installationDate.getValue(box);
  if (installationDate === null) {
    return null;
  }

  return (
    <FormattedDuration
      seconds={Math.round((Date.now() - installationDate.getTime()) / 1e3)}
      precision={2}
    />
  );
};

export default BoxInstallationDays;
