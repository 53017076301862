import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T } from "react-intl";
import queryString from "query-string";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { encode } from "comma-separated-values";
import MapSearchIcon from "mdi-material-ui/MapSearch";

import { BoxFilter, LocalLink } from "components";
import { boxType } from "types";

import { BoxList, BoxTable } from "../";

import { boxesToRowData } from "data/installations/utils";
import { InstallationsContext } from "contexts";

const rowDataToCsvFile = data => `data:text/csv;charset=utf-8,${encode(data)}`;

const scrollMargin = 200;
let defaultNbItems = 20;
if (window) {
  defaultNbItems = Math.max(defaultNbItems, Math.ceil(window.innerHeight / 50));
}
const nbNewItems = 20;

const styles = theme => ({
  sortButtons: {
    display: "flex",
  },
  card: {
    margin: "8px",
  },
  row: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    paddingTop: "8px",
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
});

class Boxes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nbItems: defaultNbItems,
      defaultFilterInput: props.filterInput,
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("scroll", this.onSceneScroll);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.onSceneScroll);
    }
  }

  setSearch = newSearch => {
    const { location, history } = this.props;
    const search = queryString.parse(location.search);
    history.replace({
      search: queryString.stringify({
        ...search,
        ...newSearch,
      }),
    });
  };

  onSceneScroll = e => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.offsetHeight;
    if (scrollY + windowHeight >= documentHeight - scrollMargin) {
      this.showMore();
    }
  };

  sort = property => {
    const { order, sortProperty } = this.props;
    this.resetNbItems();
    if (property === sortProperty) {
      this.invertSortOrder();
    } else {
      this.setSearch({
        sort: property,
        order,
      });
    }
  };

  invertSortOrder = () => {
    const { order, sortProperty } = this.props;
    const newOrder = order === "asc" ? "desc" : "asc";
    this.resetNbItems();
    this.setSearch({
      order: newOrder,
      sort: sortProperty,
    });
  };

  showMore = () => {
    const { filteredInstallations } = this.props;
    const { nbItems } = this.state;
    if (nbItems < filteredInstallations.length) {
      this.setState({
        nbItems: Math.min(this.state.nbItems + nbNewItems, filteredInstallations.length),
      });
    }
  };

  resetNbItems = () => {
    if (this.state.nbItems !== defaultNbItems) {
      this.setState({
        nbItems: defaultNbItems,
      });
    }
  };

  onFilterUpdate = filterInput => {
    this.resetNbItems();
    this.setSearch({
      search: filterInput || undefined,
    });
  };

  selectColumns = columns => {
    this.resetNbItems();
    this.props.setColumns(columns);
  };

  downloadCsv = () => {
    if (!document) {
      return;
    }
    const { filteredInstallations, filterInput } = this.props;
    const encodedUri = encodeURI(rowDataToCsvFile(boxesToRowData(filteredInstallations)));
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", encodeURI(filterInput || "data") + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const {
      classes,
      order,
      sortProperty,
      filteredInstallations,
      filterInput,
      columns,
    } = this.props;
    const { nbItems, defaultFilterInput } = this.state;
    const displayedBoxes = filteredInstallations.slice(0, nbItems);

    const mapLink = encodeURI(`/map${filterInput ? `?search=${filterInput}` : ""}`);

    return (
      <Card className={classes.card}>
        <CardContent>
          <BoxFilter
            defaultValue={defaultFilterInput}
            onUpdate={this.onFilterUpdate}
            invertSortOrder={this.invertSortOrder}
            sort={this.sort}
          />
          <span className={classes.textSecondary}>
            <T id="installationTotalCount" values={{ count: filteredInstallations.length }} />
            <Tooltip title={<T id="showOnMap" />}>
              <IconButton component={LocalLink} to={mapLink}>
                <MapSearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={<T id="download" />}>
              <IconButton onClick={this.downloadCsv}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </span>
          <Hidden smDown>
            <BoxTable
              boxes={displayedBoxes}
              sortProperty={sortProperty}
              order={order}
              sort={this.sort}
              selectedColumns={columns}
              selectColumns={this.selectColumns}
            />
          </Hidden>
          <Hidden mdUp>
            <div className={classes.textSecondary}>
              <T id="sortedBy" /> <T id={`sortOption.${sortProperty}`} />{" "}
              <T id={`sortOrder.${order}`} />
            </div>
            <BoxList boxes={displayedBoxes} />
          </Hidden>
          <p>
            {!window && displayedBoxes.length < filteredInstallations.length && (
              <Button onClick={this.showMore}>
                <T id="showMore" />
              </Button>
            )}
            {displayedBoxes.length === filteredInstallations.length && (
              <span className={classes.textSecondary}>
                {" ("}
                <T
                  id="installationRelativeCount"
                  values={{
                    displayedCount: displayedBoxes.length,
                    totalCount: filteredInstallations.length,
                  }}
                />
                )
              </span>
            )}
          </p>
        </CardContent>
      </Card>
    );
  }
}

Boxes.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  sortProperty: PropTypes.string.isRequired,
  filterInput: PropTypes.string.isRequired,
  filteredInstallations: PropTypes.arrayOf(boxType).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setColumns: PropTypes.func.isRequired,
};

const BoxesWrapper = props => (
  <InstallationsContext.Consumer>
    {({ order, sortProperty, filterInput, filteredInstallations, columns, setColumns }) => (
      <Boxes
        order={order}
        sortProperty={sortProperty}
        filterInput={filterInput}
        filteredInstallations={filteredInstallations}
        columns={columns}
        setColumns={setColumns}
        {...props}
      />
    )}
  </InstallationsContext.Consumer>
);

export default compose(withRouter, withStyles(styles))(BoxesWrapper);
