import { formatCountry, formatDatetime } from "utils/format";
import * as operators from "utils/operators";
import countries from "countries";
import flags from "flags";

import { isPriorToTreshold } from "./utils";

const EQUAL_TIMES_INTERVAL = 2000; //ms
const MILLIS_IN_A_DAY = 24 * 3600 * 1e3; //ms

const dateExport = date => {
  if (!date) return "";
  if (isNaN(date.getTime())) return "";
  return date.toISOString();
};

const zohoProjectToCountry = zoho_project => {
  return countries.find(c => c.zoho_project === zoho_project);
};

const timesWithinInterval = (timeA, timeB) => Math.abs(timeA - timeB) < EQUAL_TIMES_INTERVAL;

const boxBMSBrand = box => {
    try{
      return box.kit.bms.brand || null;
    }catch(TypeError){
      return null;
    }
}
const boxStatus = box => {
  const now = Date.now();
  const { expiration_timestamp: expiration, confirmed_expiration_timestamp: confirmation } = box;
  if (expiration && confirmation) {
    const timestampAreEqual = timesWithinInterval(expiration, confirmation);
    if (timestampAreEqual) {
      if (confirmation > now) {
        return "on";
      } else {
        if (box.contract.fully_paid) {
          return "permanent";
        }
        return "off";
      }
    }
  }
  return "unsynchronized";
};

const boxAmountToRecover = ({ credit_info }) => {
  if (!credit_info || !credit_info.amount_to_recover) {
    return null;
  }
  const { value_cents } = credit_info.amount_to_recover;
  return value_cents / 100;
};

const boxAssociatedDate = ({ box_associated_date }) => {
  const date = new Date(box_associated_date);
  if (isPriorToTreshold(date.getTime())) {
    return null;
  }
  return date;
};

const installationDate = ({ installation_date }) => {
  const date = new Date(installation_date);
  if (isPriorToTreshold(date.getTime())) {
    return null;
  }
  return date;
};

const installationDays = box => {
  const _installationDate = installationDate(box);
  if (_installationDate === null) {
    return null;
  }
  return (Date.now() - _installationDate.getTime()) / MILLIS_IN_A_DAY;
};

const contractState = box => {
  const finished_soon = box.contract.finished_soon;
  const fully_paid = box.contract.fully_paid;
  const finished = box.contract.finished;
  if (finished) {
    return "finished";
  }
  else if (fully_paid) {
    return "fullyPaid";
  }
  else if (finished_soon) {
    return "finishedSoon";
  }
  else {
    return ("notFinishedSoon")
  }
}

const normalize = name =>
  name
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

const boxProperties = {
  box: {
    getValue: box => box.bms_label,
    operator: operators.equals,
  },
  sc: {
    getValue: box => box.sc_serial,
    operator: {
      equals: (filterTerm, value) => value.indexOf(filterTerm) === value.length - filterTerm.length,
    },
  },
  client: {
    getValue: box => box.client.name,
    operator: {
      contains: (filterTerm, value) =>
        normalize(value).indexOf(normalize(filterTerm).replace("_", " ")) !== -1,
    },
    sort: Intl.Collator().compare,
  },
  contractCode: {
    getValue: box => box.contract.code,
    operator: operators.equals,
  },
  bmsBrand: {
    getValue: boxBMSBrand,
    operator: operators.equals,
    tips: ["Biolite", "Victron"],
  },
  country: {
    getValue: box => formatCountry(zohoProjectToCountry(box.zoho_project)),
    operator: {
      equals: (filterTerm, value) => normalize(filterTerm) === value,
    },
    tips: countries.map(c => c.label),
  },
  status: {
    getValue: boxStatus,
    operator: {
      equals: (filterTerm, value) => normalize(filterTerm) === value,
    },
    tips: ["on", "off", "permanent", "unsynchronized"],
  },
  expiration: {
    getValue: box => new Date(box.expiration_timestamp),
    csvExport: dateExport,
    operator: operators.date,
  },
  lastCommunication: {
    getValue: box => new Date(box.last_communication_timestamp),
    csvExport: dateExport,
    operator: operators.date,
  },
  dailyProduction: {
    getValue: ({ avg_power_7days }) => avg_power_7days && avg_power_7days.production * 24,
    operator: operators.number,
  },
  dailyConsumption: {
    getValue: ({ avg_power_7days }) => avg_power_7days && avg_power_7days.consumption * 24,
    operator: operators.number,
  },
  consumptionPercentage: {
    getValue: ({ avg_power_7days }) => {
      if (!avg_power_7days || !avg_power_7days.production) {
        return null;
      }
      return ((avg_power_7days.consumption || 0) * 100) / avg_power_7days.production;
    },
    operator: operators.number,
  },
  activationRate: {
    getValue: ({ credit_info }) => (credit_info ? credit_info.activation_rate * 100 : null),
    operator: operators.number,
  },
  lastClientCall: {
    getValue: ({ client }) => (client.last_call ? new Date(client.last_call) : null),
    csvExport: dateExport,
    operator: operators.date,
  },
  clientCallback: {
    getValue: ({ client }) => (client.callback_date ? new Date(client.callback_date) : null),
    csvExport: dateExport,
    operator: operators.date,
  },
  financialCategory: {
    getValue: ({ credit_info }) => (credit_info ? credit_info.financial_category : null),
    operator: operators.range,
    tips: ["A", "B", "C", "D", "E"],
  },
  followupCategory: {
    getValue: ({ credit_info }) => (credit_info ? credit_info.followup_category : null),
    operator: operators.range,
    tips: ["1", "2", "3", "4", "5"],
  },
  installationDate: {
    getValue: installationDate,
    csvExport: dateExport,
    operator: operators.date,
  },
  boxAssociatedDate: {
    getValue: boxAssociatedDate,
    csvExport: dateExport,
    operator: operators.date,
  },
  firstActivationDate: {
    getValue: box => (box.first_activation_date && new Date(box.first_activation_date)) || null,
    csvExport: dateExport,
    operator: operators.date,
  },
  installationDays: {
    getValue: installationDays,
    operator: operators.number,
  },
  inactiveDays: {
    getValue: ({ credit_info }) =>
      credit_info && credit_info.inactive_since
        ? (Date.now() - new Date(credit_info.inactive_since).getTime()) / MILLIS_IN_A_DAY
        : null,
    operator: operators.integer,
  },
  cheatScore: {
    getValue: ({ cheat_score }) => (isNaN(cheat_score) ? null : cheat_score),
    operator: operators.number,
  },
  amountToRecover: {
    getValue: boxAmountToRecover,
    operator: operators.number,
  },
  flag: {
    getValue: box => box.flags,
    operator: {
      equals: (filterTerm, value) => value[filterTerm],
      not: (filterTerm, value) => !value[filterTerm],
    },
    tips: flags.map(f => f.name),
  },
  contractState: {
    getValue: contractState,
    operator: operators.equals,
    tips: ["finished","fullyPaid", "finishedSoon", "notFinishedSoon"]
  }
};

export const defaultProperty = {
  getValue: box =>
    [
      box.bms_label,
      normalize(box.client.name),
      formatCountry(boxProperties.country.getValue(box)),
      box.sc_serial,
      box.contract.code,
      boxProperties.status.getValue(box),
      formatDatetime(boxProperties.expiration.getValue(box)),
    ].join(";"),
  operator: {
    contains: (filterTerm, value) => value.indexOf(normalize(filterTerm).replace("_", " ")) !== -1,
  },
};

export const redirect = property => {
  switch (property) {
    case "unpaidDays":
      return "inactiveDays";
    case "creation":
      return "installationDate";
    default:
      return null;
  }
};
export default boxProperties;
