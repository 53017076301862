import BuildIcon from "@material-ui/icons/Build";
import NavigationIcon from "@material-ui/icons/Navigation";
import { ZohoIcon, KibanaIcon, GoogleMapsIcon } from "icons";

export const gateway = {
  uri: ({ scSerial }) => `https://gateway.qotto.net/device/${scSerial}`,
  label: "Gateway",
  color: "#417690",
  icon: BuildIcon,
};

const zohoFix = {
  burkina: "qotto-burkina",
  benin: "crm",
};

const zohoBaseUrl = project => `https://app.zohocreator.eu/fabricedegaudemar/${zohoFix[project]}/`;

export const zohoClient = {
  uri: ({ id, project }) => `${zohoBaseUrl(project)}record-summary/Client_Report/${id}/`,
  iframe: ({ id, project, edit }) =>
    zohoBaseUrl(project) +
    (edit ? `client/record-edit/Client_Report/${id}/` : `record-summary/Client_Report/${id}/`),
  label: "Zoho Client",
  color: "#3d556d",
  icon: ZohoIcon,
};

export const zohoContract = {
  uri: ({ id, project }) => `${zohoBaseUrl(project)}record-summary/userlist_contracts/${id}/`,
  iframe: ({ id, project, edit }) =>
    zohoBaseUrl(project) +
    (edit
      ? `contract/record-edit/userlist_contracts/${id}/`
      : `record-summary/userlist_contracts/${id}/`),
  label: "Zoho Contract",
  color: "#3d556d",
  icon: ZohoIcon,
};

export const zohoInstallation = {
  uri: ({ id, project }) => `${zohoBaseUrl(project)}record-summary/userlist_installations/${id}/`,
  iframe: ({ id, project, edit }) =>
    zohoBaseUrl(project) +
    (edit
      ? `installation/record-edit/userlist_installations/${id}/`
      : `record-summary/userlist_installations/${id}/`),
  label: "Zoho Installation",
  color: "#3d556d",
  icon: ZohoIcon,
};

export const googleMaps = {
  uri: ({ lat, lon }) =>
    `https://www.google.com/maps/search/?api=1&query=${lat.toFixed(5)},${lon.toFixed(5)}`,
  label: "Google Maps",
  color: "#4285f4",
  icon: GoogleMapsIcon,
};

export const navigation = {
  uri: ({ lat, lon }) => `geo://?q=${lat.toFixed(5)},${lon.toFixed(5)}`,
  label: "Navigation",
  color: "#444",
  icon: NavigationIcon,
};

export const kibana = {
  uri: ({ scSerial }) =>
    "https://kibana.qotto.net/ArOiIgqaKlfSNePZOU4R/app/kibana" +
    "#/dashboard/b8ef8500-406c-11e7-b09a-83a599029e9f" +
    "?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))" +
    "&_a=(description:'',filters:!(),fullScreenMode:!f," +
    "options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t)," +
    "panels:!((gridData:(h:4,i:'2',w:12,x:0,y:4),id:'51c6fb10-406c-11e7-b09a-83a599029e9f',panelIndex:'2',type:visualization,version:'6.2.3')," +
    "(embeddableConfig:(vis:(colors:('Average+Consumed+power':%23EAB839,'Battery+Power':%23E0F9D7,'Battery+current':%23BF1B00,'Battery+voltage':%23BF1B00,Charge:%23629E51,'Consumed+power':%23F29191,'Consumed+power+AVG':%23EAB839,'Panel+Current':%23F9D9F9,'Panel+Power':%231F78C1,'Panel+current':%230A50A1,'Panel+power':%23B7DBAB,'Panel+voltage':%2370DBED,'Temp+(da%C2%B0C)':%23962D82,'Temp+(d%C2%B0C)':%23EA6460),legendOpen:!t))," +
    "gridData:(h:4,i:'3',w:12,x:0,y:0),id:fb5c6ce0-4617-11e7-bd9d-19cc2755252c,panelIndex:'3',type:visualization,version:'6.2.3'))," +
    `query:(language:lucene,query:'${scSerial}'),timeRestore:!f,title:Panels,viewMode:view)`,
  iframe: ({ scSerial, minDate, maxDate }) =>
    "https://kibana.qotto.net/ArOiIgqaKlfSNePZOU4R/app/kibana" +
    "#/dashboard/b8ef8500-406c-11e7-b09a-83a599029e9f?embed=true" +
    `&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${minDate.toISOString()}',mode:absolute,to:'${maxDate.toISOString()}'))` +
    "&_a=(description:'',filters:!(),fullScreenMode:!f," +
    "options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t)," +
    "panels:!((gridData:(h:4,i:'2',w:12,x:0,y:4),id:'51c6fb10-406c-11e7-b09a-83a599029e9f',panelIndex:'2',type:visualization,version:'6.2.3')," +
    "(embeddableConfig:(vis:(colors:('Average+Consumed+power':%23EAB839,'Battery+Power':%23E0F9D7,'Battery+current':%23BF1B00,'Battery+voltage':%23BF1B00,Charge:%23629E51,'Consumed+power':%23F29191,'Consumed+power+AVG':%23EAB839,'Panel+Current':%23F9D9F9,'Panel+Power':%231F78C1,'Panel+current':%230A50A1,'Panel+power':%23B7DBAB,'Panel+voltage':%2370DBED,'Temp+(da%C2%B0C)':%23962D82,'Temp+(d%C2%B0C)':%23EA6460),legendOpen:!t))," +
    "gridData:(h:4,i:'3',w:12,x:0,y:0),id:fb5c6ce0-4617-11e7-bd9d-19cc2755252c,panelIndex:'3',type:visualization,version:'6.2.3'))," +
    `query:(language:lucene,query:'${scSerial}'),timeRestore:!f,title:Panels,viewMode:view)`,
  label: "Kibana",
  color: "#3caed3",
  icon: KibanaIcon,
};
