import React from "react";

import { boxType } from "types";

const BoxFollowupCategory = ({ box }) => {
  const { credit_info } = box;

  if (!credit_info) {
    return null;
  }

  const grade = (parseInt(credit_info.followup_category, 10) - 1) / 5;
  const color = `hsl(${100 - grade * 100}, 100%, 35%)`;

  return <b style={{ color }}>{credit_info.followup_category}</b>;
};

BoxFollowupCategory.propTypes = {
  box: boxType.isRequired,
};

export default BoxFollowupCategory;
