import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { CustomPrimaryColor } from "components";
import { FormattedMessage as T } from "react-intl";
import CalendarRemove from "mdi-material-ui/CalendarRemove";

import allFlags from "contract-flags";

const BoxContractState = ({ box }) => {
  const finished  = box.contract.finished ;
  const flag_finished = allFlags[2]
  const fully_paid  = box.contract.fully_paid ;
  const flag_fully_paid = allFlags[1]
  const finished_soon  = box.contract.finished_soon ;
  const flag_finished_soon = allFlags[0]

  return (
    <span>
      { finished ?
        <>
        <CustomPrimaryColor color={flag_fully_paid.color}>
          <Tooltip title={<T id={`flag.${flag_fully_paid.title}`} />}>
            <flag_fully_paid.icon color="primary"/>
          </Tooltip>
        </CustomPrimaryColor>
        <CustomPrimaryColor color={flag_finished.color}>
          <Tooltip title={<T id={`flag.${flag_finished.title}`} />}>
            <flag_finished.icon color="primary"/>
          </Tooltip>
        </CustomPrimaryColor>
        </>
        : fully_paid ?
        <>
        <CustomPrimaryColor color={flag_fully_paid.color}>
          <Tooltip title={<T id={`flag.${flag_fully_paid.title}`} />}>
            <flag_fully_paid.icon color="primary"/>
          </Tooltip>
        </CustomPrimaryColor>
          <Tooltip title={<T id={`flag.contract-unfinished`} />}>
            <CalendarRemove nativeColor={"orangered"}/>
          </Tooltip>
        </>
        : finished_soon ?
        <CustomPrimaryColor color={flag_finished_soon.color}>
          <Tooltip title={<T id={`flag.${flag_finished_soon.title}`} />}>
            <flag_finished_soon.icon color="primary"/>
          </Tooltip>
        </CustomPrimaryColor>
        : '' }
    </span>
  );
};

export { BoxContractState as BoxContractStateComponent };

export default BoxContractState;
