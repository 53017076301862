import React from "react";
import { FormattedNumber } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { boxType } from "types";

const styles = {};

const BoxAmountToRecover = ({ box }) => {
  if (!box.credit_info || !box.credit_info.amount_to_recover) {
    return null;
  }

  const { currency, value_cents } = box.credit_info.amount_to_recover;
  return <FormattedNumber value={value_cents / 100} style={`currency`} currency={currency} />;
};

BoxAmountToRecover.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(BoxAmountToRecover);
