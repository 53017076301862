import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { FormattedMessage as T } from "react-intl";
import { compose } from "redux";

import { CountrySelect } from "./components";
import { CountryContext } from "contexts";

const styles = theme => ({
  title: {
    marginRight: "12px",
    color: "inherit",
  },
});

const AppTitle = ({ classes, location }) => {
  return (
    <Typography variant="h6" noWrap className={classes.title}>
      <T id="appTitle" />
      <CountryContext.Consumer>
        {selectedCountry => <CountrySelect location={location} selectedCountry={selectedCountry} />}
      </CountryContext.Consumer>
    </Typography>
  );
};

AppTitle.propTypes = {
  leftAppBarComponent: PropTypes.node,
  rightAppBarComponent: PropTypes.node,
};

export default compose(withStyles(styles), withRouter)(AppTitle);
