export default {
  appTitle: "Qotto",
  world: "Monde",
  home: "Accueil",
  map: "Carte",
  help: "Aide",
  contact: "Nous contacter",
  profile: "Profil",
  share: "Partager",
  installations: "Contrats",
  installation: "Contrat",
  installationBreadcrumb: "Contrat {code}",
  noData: "Donnée manquante",
  pleaseLogin: "Veuillez vous connecter pour accéder au contenu",
  unauthorized:
    "Vous n'avez pas accès à ce contenu. Vous pouvez essayer de vous reconnecter ou de contacter votre supérieur si le problème persiste.",
  searchBar: "Rechercher...",
  on: "Actif",
  off: "Inactif",
  from: "Depuis le",
  to: "Jusqu'au",
  timezone: "Fuseau horaire {timezone}",
  cancel: "Annuler",
  close: "Fermer",
  signin: "Se connecter",
  signinAgain: "Se reconnecter",
  signout: "Se déconnecter",
  username: "Identifiant",
  password: "Mot de passe",
  updateAvailable: "Nouvelle version disponible !",
  quotaTooSmall:
    "Vous n'avez pas assez de mémoire pour utiliser l'app. Veuillez libérer de l'espace, et rééssayer.",
  usageTooHigh: "Vous n'avez presque plus de mémoire disponible. Veuillez libérer de l'espace.",
  serviceWorkerNotSupported:
    "Votre navigateur n'est pas compatible avec le dash. Veuillez utiliser la dernière version de Google Chrome.",
  unknownCouchdbError:
    "Une erreur a eu lieu pendant la récupération des données. Veuillez contacter l'équipe des développeurs Qotto pour leur signaler ce dysfonctionnement, en indiquant l'information suivante : {errorMessage}",
  reload: "Recharger",
  download: "Télécharger",
  copy: "Copier",
  copyMessage: "Copié dans le presse-papier",
  send: "Envoyer",
  save: "Sauvegarder",
  delete: "Supprimer",
  validate: "Valider",
  subscribe: "S'abonner",
  unsubscribe: "Se désabonner",

  runningInstallationPrefix: "expire",
  expiredInstallationPrefix: "a\xa0expiré",
  expirationDeterminant: "le",
  boxInstalledPrefix: "Box changée le",
  firstActivatedPrefix: "Première activation faite le",
  installationDate: "Date de l'installation",
  firstActivationDate: "Date de la première activation",
  installationDays: "Âge de l'installation",
  cheatScore: "Indice de triche",
  installationDatePrefix: "Installation créée le",
  lastCommunicationPrefix: "Dernier contact avec la smartcard le",
  consumption: "consommation",
  columnsOptionsTitle: "Colonnes sélectionnées",
  selectColumns: "Selectionner les colonnes",
  sortOptionsTitle: "Options de tri",
  sortBy: "Trier par",
  sortedBy: "Trié par",
  "sortOrder.asc": "par ordre ascendant",
  "sortOrder.desc": "par ordre descendant",
  "sortOption.client": "le nom du client",
  "sortOption.box": "le numéro de la bms",
  "sortOption.expiration": "l'échéance",
  "sortOption.lastCommunication": "date du dernier contact sc",
  "sortOption.dailyProduction": "la production d'énergie",
  "sortOption.dailyConsumption": "la consommation d'énergie",
  "sortOption.activationRate": "le taux d'activation",
  "sortOption.consumptionPercentage": "le pourcentage de consommation",
  "sortOption.lastClientCall": "date du dernier appel client",
  "sortOption.clientCallback": "date prévue de rappel du client",
  "sortOption.financialCategory": "la catégorie financière",
  "sortOption.followupCategory": "la catégorie de relance",
  "sortOption.installationDate": "la date de l'installation",
  "sortOption.installationDays": "l'âge de l'installation",
  "sortOption.cheatScore": "l'indice de triche",
  filterOptionsTitle: "Ajouter un filtre",
  "filterOption.bmsBrand:Biolite": "Afficher les installations Biolite",
  "filterOption.bmsBrand:Victron": "Afficher les installations Victron",
  "filterOption.client": "Filtrer les noms des clients",
  "filterOption.box": "Filtrer les numéros de bms",
  "filterOption.sc": "Filtrer les numéros de smartcard",
  "filterOption.expiration": "Filtrer les dates d'expiration",
  "filterOption.inactiveDays.gt": "Filtrer le nombre de jours inactifs",
  "filterOption.country": "Filtrer les pays",
  "filterOption.status:on": "Afficher les installations actives",
  "filterOption.status:off": "Afficher les installations inactives",
  "filterOption.status:unsynchronized": "Afficher les installations désynchronisées",
  "filterOption.status:permanent": "Afficher les installations activées de manière permanente",
  "filterOption.contractState:finished": "Afficher les contrats terminés",
  "filterOption.contractState:fullyPaid": "Afficher les contrats entièrement payés mais non terminés",
  "filterOption.contractState:finishedSoon": "Afficher les contrats avec moins de 2 mois à payer",
  "filterOption.contractState:notFinishedSoon": "Afficher les contrats avec plus de 2 mois à payer",
  filterOptions: "Filtres",
  sortOptions: "Options de tri",
  invertSortOrder: "Inverser l'ordre de tri",
  installationTotalCount: `{count, plural,
        one {# contrat}
        other {# contrats}
    }`,
  installationRelativeCount: `{displayedCount, plural,
        one {# affichée}
        other {# affichées}
    } sur {totalCount}`,
  showMore: "Voir plus",
  noInstallationFound: "Aucune installation correspondante",
  externalLinks: "Liens externes",
  showOnMap: "Voir sur la carte",
  showOnList: "Voir la liste",
  fitMapToInstallations: "Recentrer sur les installations",

  label: "Numero",
  client: "Client",
  status: "Statut",
  contract: "Contrat",
  bmsBrand: "BMS Brand",
  expiration: "Date d'expiration",
  lastCommunication: "Dernier contact smartcard",
  dailyProduction: "Production journalière",
  dailyConsumption: "Consommation journalière",
  consumptionPercentage: "Pourcentage de consommation",
  financialCategory: "Catégorie financière",
  followupCategory: "Catégorie de relance",
  financialCategoryInfo: "Catégorie financière du client :",
  followupCategoryInfo: "Catégorie de relance du client :",
  amountToRecover: "Montant à régulariser",
  amountToRecoverInfo: "Montant à régulariser au premier {month} :",
  amountToRecoverExplanation:
    "Le montant à régulariser est calculé au début de chaque mois, à minuit UTC. La valeur affichée date du {date}.",
  activationRate: "Taux d'activation",
  activationRateInfo: "Taux d'activation :",
  weeklyActivationsGrid: "Grille des activations réussies",
  weeklyActivationsGridInfo: "Grille des cotisations hebdomadaires {helpButton} :",
  weeklyActivationsTooltipRange: "De {fromDate} à {toDate}",
  weeklyActivationsTooltipActivation: `Activée {days, plural,
      one {# jour}
      other {# jours}
    } sur 7`,
  cheatScoreInfo: "Indice de triche :",
  unsynchronized: "non synchronisée",
  energyAvgProduction:
    "Production journalière moyenne des 7 derniers jours: {production}, consommation: {consumption} ({percentage}%)",
  last7Days: "7 jours",
  last30Days: "30 jours",
  last6Months: "6 mois",
  invalidDate: "Date invalide",
  signinDialogTitle: "Se connecter",
  replicatingDatabase: "Récupération des données...",
  codeActivation: "Activation par code",
  RefreshCplus: "Renvoyer l'activation Canal+",
  generateCode: "Générer code d'activation",
  requestCode: "Code demande",
  activationCode: "Code d'activation",
  CanalPlusActivationSent: "L'activation a bien été envoyée au décodeur Canal+",
  bmsDoesntExist: "Cette BMS ne semble pas être enregistrée auprès de notre fournisseur. Vérifiez son numéro de série.",
  decoderDoesntExist:"Cette decodeur Canal+ ne semble pas être enregistrée pour cette contrat dan notre base des donnes. Vérifiez son numéro de série.",
  deviceServerDoesntRespond:"Le service d'activation ne répond pas, veuillez réessayer plus tard.",
  paymentSummary: "Récapitulatif",
  invalidRequest:"La demande n'est pas valide, veuillez vous assurer que vos informations sont correctes et que votre contrat n'a pas expiré.",
  invalidRequestSolaris:"La demande n'est pas valide, veuillez vous assurer que vos informations, y compris le code de demande, sont correctes et que votre contrat n'a pas expiré.",
  invalidAuth:"La demande n'est pas authentifiée, veuillez vérifier votre connexion et contacter notre équipe.",
  notesOnClient: "Informations complémentaires sur le client",
  clientNewMessage: "Envoyer un message",
  startConversation: "Démarrer une conversation",
  noMessage: "Aucune conversation n'est associée à {client}.",
  userIsSubscribed: "Vous êtes abonné à la conversation",
  userIsUnsubscribed: "Vous n'êtes pas abonné à la conversation",
  flags: "Marqueurs :",
  "flag.no-gsm": "Hors réseau",
  "flag.no-gsm.description":
    "Marqueur attribué aux installations qui ne sont pas couvertes par le réseau GSM, et donc apparaissant toujours comme désynchronisées",
  "flag.cheating": "Tricheur",
  "flag.cheating.description":
    "Marqueur attribué lorsque le client semble faire une utilisation détournée de son matériel (par exemple, en arrêtant de payer et en se branchant directement sur la batterie)",
  "flag.uninstall-refused": "Refus désinstallation",
  "flag.uninstall-refused.description":
    "Marqueur attribué lorsque le client s'oppose à la désinstallation",
  "flag.vip": "VIP",
  "flag.vip.description": "Marqueur attribué aux clients importants (politique, influenceur…)",
  "flag.push-button": "Bouton poussoir",
  "flag.push-button.description": "La BMS est équipée d'un bouton poussoir",
  "contractState": "État du contrat",
  "endOfContract": "Fin de contrat",
  "contract-flags": "Marqueurs de fin de contrat (non modifiables) :",
  "flag.contract-finished-soon": "Bientôt terminé",
  "flag.contract-finished-soon.description": "Marqueur attribué aux contrats bientôt terminés (moins de 2 mois à payer)",
  "flag.contract-fully-paid": "Entièrement payé",
  "flag.contract-fully-paid.description": "Marqueur attribué aux contrats que le client a fini de payer",
  "flag.contract-finished": "Terminé",
  "flag.contract-finished.description": "Marqueur attribué aux contrats que le client a fini de payer et arrivés à date d'expiration",
  "flag.contract-unfinished": "Non terminé",
  "status:unsynchronized":"Désynchronisé",
  "status:permanent":"Activé de manière permanente",

  showInfos: "Détails",
  showKibana: "Télémétrie",
  showComments: "Interactions",
  showZohoClient: "Client zoho",
  showZohoContract: "Contrat zoho",
  showZohoInstallation: "Installation zoho",
  zohoEditMode: "Passer en mode édition",
  zohoViewMode: "Revenir en mode lecture",

  changeTimezone: "Changer de fuseau horaire",
  searchTimezone: "Rechercher un fuseau",
  currentlyOnTimezone: "Actuellement sur",
  newTimezone: "Nouveau fuseau :",
  tzSnackbar:
    "Le fuseau de votre machine a changé à {clientTimezone}. Le dash affiche désormais les heures dans le fuseau {timezone}.",
  offsetSnackbar: "L'horloge de votre machine a {offset}. Veuillez la mettre à l'heure.",
  positiveOffset: "d'avance",
  negativeOffset: "de retard",

  lastCallDate: "Date de dernier appel du client",
  callbackDate: "Date prévue de rappel du client",
  now: "Mettre la date actuelle",

  reportTitle: "Une erreur est survenue !",
  reportError:
    "Pour nous aider à la corriger, pourriez-vous s'il vous plaît nous dire ce que vous avez essayé de faire ?",
  sendReport: "Ecrire un rapport",
  thankYouTitle: "Merci !",
  thankYouContent: "Merci d'avoir pris le temps de nous aider à résoudre ce problème.",

  language: "Langue :",
  "language.en": "Anglais",
  "language.fr": "Français",
  changeLanguage: "Changer de langue",
  changeLanguageTo: "Changer la langue en :",
  currentlyOnLanguage: "Le dash s'affiche actuellement en",
  openHelp: "Ouvrir l'aide",

  "formatted-duration.year": "{value, plural, one {# année} other {# années}}",
  "formatted-duration.month": "{value} mois",
  "formatted-duration.day": "{value, plural, one {# jour} other {# jours}}",
  "formatted-duration.hour": "{value, plural, one {# heure} other {# heures}}",
  "formatted-duration.minute": "{value, plural, one {# minute} other {# minutes}}",
  "formatted-duration.second": "{value, plural, one {# seconde} other {# secondes}}",
};
