const min2Digits = number => (number > 9 ? "" : "0") + number;

export const formatDatetime = d =>
  [d.getFullYear(), min2Digits(d.getMonth() + 1), min2Digits(d.getDate())].join("-") +
  " " +
  [min2Digits(d.getHours()), min2Digits(d.getMinutes()), min2Digits(d.getSeconds())].join(":");

export const formatWh = watts => `${(watts * 24).toFixed(2)}\xa0Wh`;

export const formatCountry = country => country && country.label;
