import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { FormattedMessage as T } from "react-intl";

class CopyToClipboardButton extends PureComponent {
  state = {
    copied: false,
  };

  toggleCopiedSnackbar = () => {
    this.setState({
      copied: !this.state.copied,
    });
  };

  render() {
    const { text } = this.props;
    const { copied } = this.state;

    return (
      <Fragment>
        <CopyToClipboard text={text} onCopy={this.toggleCopiedSnackbar}>
          <Button>
            <T id="copy" />
          </Button>
        </CopyToClipboard>
        <Snackbar
          open={copied}
          autoHideDuration={5000}
          onClose={this.toggleCopiedSnackbar}
          message={<T id="copyMessage" />}
        />
      </Fragment>
    );
  }
}

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
