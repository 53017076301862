import React, { Fragment } from "react";
import { FormattedMessage as T, FormattedTime, FormattedDate } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";

import { boxType } from "types";
import { BoxAmountToRecover } from "components";

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const getLastComputeDate = () => {
  const lastComputeDate = new Date();
  lastComputeDate.setHours(0, 0);
  lastComputeDate.setDate(1);
  lastComputeDate.setMinutes(lastComputeDate.getMinutes() - lastComputeDate.getTimezoneOffset());
  return lastComputeDate;
};

class AmountToRecoverInfo extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {
    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

    const lastComputeDate = getLastComputeDate();

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T id="amountToRecover" />
          </DialogTitle>
          <DialogContent>
            <T
              id="amountToRecoverExplanation"
              values={{
                date: (
                  <FormattedTime
                    value={lastComputeDate}
                    year="numeric"
                    month="long"
                    day="numeric"
                  />
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <span>
          <T
            id="amountToRecoverInfo"
            values={{
              month: <FormattedDate value={new Date()} month="long" />,
            }}
          />{" "}
          <strong>
            <BoxAmountToRecover box={box} />
          </strong>{" "}
          <IconButton fontSize="inherit" className={classes.helpButton} onClick={this.toggleDialog}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Fragment>
    );
  }
}

AmountToRecoverInfo.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(AmountToRecoverInfo);
