import React from "react";
import PropTypes from "prop-types";
import { FormattedDate } from "react-intl";

const BoxDate = ({ value }) => {
  if (!value) {
    return null;
  }

  return (
    <FormattedDate
      value={new Date(value)}
      timeZone="UTC"
      year="numeric"
      month="2-digit"
      day="2-digit"
    />
  );
};

BoxDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default BoxDate;
