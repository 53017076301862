import React, { useState } from "react";
import classNames from "classnames";
import { FormattedMessage as T } from "react-intl";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import { PouchdbContext } from "contexts";
import { CustomPrimaryColor } from "components";

import allFlags, { FlagDefinition } from "contract-flags";

const styles = createStyles({
    root: {},
    button: {
      margin: "2px",
      padding: "2px 4px",
    },
    checkbox: {
      padding: 0,
    },
    checkedCheckbox: {
      color: "white !important",
    },
  });

interface ContractFlagsProps extends WithStyles<typeof styles> {
    contractCode: string;
    finished: number;
    finished_soon: number;
    fully_paid: number;
  }


const ContractFlags = (props: ContractFlagsProps) => {
    const { contractCode, finished, finished_soon, fully_paid, classes } = props;
    return (
    <span>
      {allFlags.map((flag: FlagDefinition) => (
        <CustomPrimaryColor color={flag.color}>
            <Tooltip title={<T id={`flag.${flag.title}.description`} />}>
                <Button
                key={flag.name}
                size="small"
                color="primary"
                variant={eval(flag.name) ? "contained" : "text"}
                className={classes.button}
                >
                    <Checkbox
                        checked={eval(flag.name)}
                        disabled={true}
                        className={classNames(classes.checkbox, {
                        [classes.checkedCheckbox]: eval(flag.name),
                        })}
                    />
                    &nbsp;
                    <flag.icon />
                    &nbsp;
                    <T id={`flag.${flag.title}`} />
                    &nbsp;
                </Button>
            </Tooltip>
        </CustomPrimaryColor>
      )
      )}
    </span>

    )
}

export default withStyles(styles)(ContractFlags);
