import React from "react";

import { boxType } from "types";

const BoxFinancialCategory = ({ box }) => {
  const { credit_info } = box;

  if (!credit_info) {
    return null;
  }

  const grade = (credit_info.financial_category.charCodeAt(0) - 65) / 5;
  const color = `hsl(${100 - grade * 100}, 100%, 35%)`;

  return <b style={{ color }}>{credit_info.financial_category}</b>;
};

BoxFinancialCategory.propTypes = {
  box: boxType.isRequired,
};

export default BoxFinancialCategory;
