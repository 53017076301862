import React from "react";
import PropTypes from "prop-types";
import { FormattedDate } from "react-intl";

const BoxDateTime = ({ value }) => {
  if (!value) {
    return null;
  }
  return (
    <FormattedDate
      value={new Date(value)}
      year="numeric"
      month="2-digit"
      day="2-digit"
      hour="2-digit"
      minute="2-digit"
    />
  );
};

BoxDateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default BoxDateTime;
