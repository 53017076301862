import React from "react";
import { FormattedMessage as T } from "react-intl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import { LoadingButton } from "components";

import { InstallationsContext, PouchdbContext } from "contexts";

const styles = {
  form: {
    position: "relative",
    marginTop: "8px",
  },
  submitLine: {
    position: "absolute",
    bottom: "12px",
    right: "12px",
  },
  progressWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

class ClientNote extends React.PureComponent {
  state = {
    defaultContent: null,
    content: null,
    processing: false,
  };

  static getDerivedStateFromProps = (props, state) => {
    const defaultContent = props.note ? props.note.content : "";
    if (defaultContent !== state.defaultContent) {
      return {
        defaultContent,
        content: defaultContent,
        processing: false,
      };
    }
    return state;
  };

  handleChange = e => {
    this.setState({
      content: e.target.value,
    });
  };

  cancelChanges = () => {
    this.setState({
      content: this.state.defaultContent,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { note, client, putDoc } = this.props;
    const { content, defaultContent } = this.state;
    const hasChanged = content !== defaultContent;
    if (!hasChanged) {
      return false;
    }
    const newDoc = {
      _id: `urn.qotto.client_note:${client.zoho_id}`,
      note_id: client.zoho_id,
      type: "client_note",
      ...note,
      content,
    };
    if (content === "") {
      newDoc._deleted = true;
    }
    this.setState({ processing: true });
    putDoc(newDoc);
    return false;
  };

  render() {
    const { classes, loading } = this.props;
    const { content, defaultContent, processing } = this.state;

    const hasChanged = content !== defaultContent;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <TextField
          label={<T id="notesOnClient" />}
          fullWidth
          variant="outlined"
          value={content}
          onChange={this.handleChange}
          disabled={loading}
          multiline
          rows="4"
        />
        {loading && (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        )}
        {hasChanged && (
          <div className={classes.submitLine}>
            <LoadingButton
              type="submit"
              size="small"
              variant="contained"
              loading={processing}
              disabled={processing}
              color="primary"
            >
              <SaveIcon /> <T id="save" />
            </LoadingButton>
            <Button size="small" disabled={processing} onClick={this.cancelChanges}>
              <T id="cancel" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

ClientNote.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const ClientNoteWrapper = props => (
  <InstallationsContext.Consumer>
    {({ notes }) => (
      <PouchdbContext.Consumer>
        {({ putDoc, readWriteLoading }) => (
          <ClientNote
            note={notes.find(note => note.note_id === props.client.zoho_id)}
            putDoc={putDoc}
            loading={readWriteLoading}
            {...props}
          />
        )}
      </PouchdbContext.Consumer>
    )}
  </InstallationsContext.Consumer>
);

export default withStyles(styles)(ClientNoteWrapper);
