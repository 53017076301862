import React from "react";
import { boxType } from "types";

const BoxLabel = ({ box }) => (
  <span>
    <strong>{box.bms_label}</strong>
    {" / "}
    <strong>{box.sc_label}</strong>
  </span>
);

BoxLabel.propTypes = {
  box: boxType.isRequired,
};

export default BoxLabel;
