import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { BoxClient } from "components";
import { boxType } from "types";

const styles = {
  icon: {
    verticalAlign: "middle",
  },
};

const BoxTitle = ({ box, classes }) => <BoxClient box={box} />;

BoxTitle.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(BoxTitle);
