import React from "react";
import { FormattedMessage as T } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";

import { CustomPrimaryColor } from "components";
import allFlags from "flags";

interface BoxFlagsProps {
  box: any;
}

const BoxFlags = (props: BoxFlagsProps) => {
  const { box } = props;
  if (!box.flags) return null;
  return allFlags
    .filter(flag => box.flags[flag.name])
    .map(flag => (
      <CustomPrimaryColor key={flag.name} color={flag.color}>
        <Tooltip title={<T id={`flag.${flag.name}`} />}>
          <flag.icon fontSize="inherit" color="primary" />
        </Tooltip>
      </CustomPrimaryColor>
    ));
};

export default BoxFlags;
