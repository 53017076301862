import messages from "./messages";
import * as mdFiles from "./docs";

const filesToMessages = files => {
  const res = {};
  Object.keys(files).forEach(key => {
    res[`_docs.${key}`] = files[key];
  });
  return res;
};

export default {
  ...messages,
  ...filesToMessages(mdFiles),
};
