import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import ButtonBase from "@material-ui/core/ButtonBase";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T } from "react-intl";

const styles = theme => ({
  newTimezone: {
    color: theme.palette.primary.dark,
  },
  selectContainer: {
    marginBottom: "8px",
  },
});

class LanguageSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      language: props.language,
    };
  }

  toggleOpen = () => {
    const open = !this.state.open;
    this.setState({ open });
  };

  onLanguageChange = event => {
    const language = event.target.value;
    this.setState({ language });
  };

  onSubmit = e => {
    e.preventDefault();
    const { language } = this.state;
    this.props.setLanguage(language);
    this.toggleOpen();
    return false;
  };

  render() {
    const { classes, language: currentLanguage, availableLanguages } = this.props;
    const { open, language } = this.state;

    const hasChanged = currentLanguage !== language;

    return (
      <Fragment>
        <ButtonBase onClick={this.toggleOpen}>
          <T id="language" />
          &nbsp;
          <T id={`language.${currentLanguage}`} />
        </ButtonBase>
        <Dialog open={open} onClose={this.toggleOpen}>
          <form onSubmit={this.onSubmit}>
            <DialogTitle>
              <T id="changeLanguage" />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Typography gutterBottom>
                <T id="currentlyOnLanguage" />{" "}
                <strong>
                  <T id={`language.${currentLanguage}`} />
                </strong>
              </Typography>
              <Typography gutterBottom>
                <T id="changeLanguageTo" />
              </Typography>
              <FormControl>
                <Select value={language} onChange={this.onLanguageChange}>
                  {availableLanguages.map(lang => (
                    <MenuItem key={lang} value={lang}>
                      <T id={`language.${lang}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleOpen}>
                <T id="cancel" />
              </Button>
              <Button type="submit" disabled={!hasChanged} variant="contained" color="primary">
                <T id="validate" />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(LanguageSelect);
