import React, { Fragment } from "react";
import Downshift from "downshift";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T, FormattedDate } from "react-intl";
import allTimezones from "./allTimezones";

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : allTimezones.filter(tz => tz.toLowerCase().indexOf(inputValue) > -1).slice(0, 5);
};

const styles = theme => ({
  newTimezone: {
    color: theme.palette.primary.dark,
  },
  selectContainer: {
    marginBottom: "8px",
  },
});

class TimezoneSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      timezone: props.timezone,
      input: "",
      now: props.getDate(),
    };
  }

  toggleOpen = () => {
    const open = !this.state.open;
    this.setState({
      open,
      now: open ? this.props.getDate() : this.state.now,
    });
    if (open) {
      this.setDateInterval();
    } else {
      this.clearDateInterval();
    }
  };

  setInput = input => {
    this.setState({ input });
  };

  setDateInterval = () => {
    this.dateInterval = setInterval(() => {
      this.setState({
        now: this.props.getDate(),
      });
    }, 1000);
  };

  clearDateInterval = () => {
    if (this.dateInterval) {
      clearInterval(this.dateInterval);
      delete this.dateInterval;
    }
  };

  onTimezoneChange = timezone => {
    this.setState({
      timezone,
      input: "",
    });
  };

  componentWillUnmount() {
    this.clearDateInterval();
  }

  onSubmit = e => {
    e.preventDefault();
    const { timezone } = this.state;
    this.props.setTimezone(timezone);
    this.toggleOpen();
    return false;
  };

  render() {
    const { classes, timezone: currentTimezone } = this.props;
    const { open, timezone, now, input } = this.state;

    const hasChanged = currentTimezone !== timezone;

    return (
      <Fragment>
        <ButtonBase onClick={this.toggleOpen}>
          <T id="timezone" values={{ timezone: currentTimezone }} />
        </ButtonBase>
        <Dialog open={open} onClose={this.toggleOpen}>
          <form onSubmit={this.onSubmit}>
            <DialogTitle>
              <T id="changeTimezone" />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Typography gutterBottom>
                <T id="currentlyOnTimezone" /> <strong>{currentTimezone}</strong>
                {" ("}
                <FormattedDate
                  value={now}
                  timeZone={currentTimezone}
                  hour="2-digit"
                  minute="2-digit"
                  second="2-digit"
                  timeZoneName="short"
                />
                )
              </Typography>
              <Downshift
                id="downshift-simple"
                onChange={this.onTimezoneChange}
                inputValue={input}
                onInputValueChange={this.setInput}
              >
                {({
                  getInputProps,
                  getItemProps,
                  getMenuProps,
                  highlightedIndex,
                  inputValue,
                  isOpen,
                }) => (
                  <div className={classes.selectContainer}>
                    <TextField label={<T id="searchTimezone" />} InputProps={getInputProps()} />
                    <div {...getMenuProps()}>
                      {isOpen && (
                        <Paper square>
                          {getSuggestions(inputValue).map((suggestion, index) => (
                            <MenuItem
                              {...getItemProps({ item: suggestion })}
                              key={suggestion}
                              selected={highlightedIndex === index}
                              component="div"
                            >
                              {suggestion}
                            </MenuItem>
                          ))}
                        </Paper>
                      )}
                    </div>
                  </div>
                )}
              </Downshift>
              <input type="hidden" name="timezone" value={hasChanged ? timezone : ""} required />
              {hasChanged && (
                <Typography>
                  <T id="newTimezone" /> <strong className={classes.newTimezone}>{timezone}</strong>
                  {" ("}
                  <FormattedDate
                    value={now}
                    timeZone={timezone}
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                    timeZoneName="short"
                  />
                  )
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleOpen}>
                <T id="cancel" />
              </Button>
              <Button type="submit" disabled={!hasChanged} variant="contained" color="primary">
                <T id="validate" />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(TimezoneSelect);
