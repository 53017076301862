import React from "react";
import { compose } from "redux";
import { FormattedMessage as T } from "react-intl";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import { withStyles } from "@material-ui/core/styles";
import uuidv4 from "uuid/v4";

import { UserContext, DateContext, InstallationsContext, PouchdbContext } from "contexts";

import { ChatMessage } from "./components";

const SYNCED_SORT_THRESHOLD = 1e3; // 1 second

const styles = {
  form: {
    display: "flex",
    alignItems: "flex-end",
  },
  messageInput: {
    flex: 1,
    marginRight: "12px",
  },
  messagesContainer: {
    maxHeight: "400px",
    overflow: "auto",
    paddingBottom: "24px",
  },
};

class ClientChat extends React.PureComponent {
  state = {
    messageContent: "",
  };

  handleChange = e => {
    this.setState({
      messageContent: e.target.value,
    });
  };

  getClientChat = async () => {
    let { clientChat, putDoc, client } = this.props;
    if (clientChat) return clientChat;

    clientChat = {
      _id: `urn.qotto.client_chat:${uuidv4()}`,
      type: "client_chat",
      chat_id: `urn.qotto.chat:${uuidv4()}`,
      client_id: client.zoho_id,
    };
    await putDoc(clientChat);

    return clientChat;
  };

  onEnterPress = (user, getDate) => e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.onMessageAdd(user, getDate);
    }
  };

  onSubmit = (user, getDate) => async e => {
    e.preventDefault();
    this.onMessageAdd(user, getDate);
    return false;
  };

  onMessageAdd = async (user, getDate) => {
    const { putDoc } = this.props;
    let { messageContent } = this.state;
    messageContent = messageContent.trim();
    if (messageContent) {
      const now = getDate().getTime();
      const clientChat = await this.getClientChat();

      const id = uuidv4();
      const newDoc = {
        _id: `urn.qotto.chat_message:${id}`,
        type: "chat_message",
        date_typed: now,
        message_id: id,
        chat_id: clientChat.chat_id,
        author: {
          displayed_name: user.metadata.displayed_name,
          user_id: user.uuid,
        },
        content: messageContent,
      };

      await putDoc(newDoc);
      this.setState({
        messageContent: "",
      });
    }
  };

  unsubscribe = async () => {
    const { chatSubscription, putDoc } = this.props;
    if (!chatSubscription) return;
    await putDoc({
      ...chatSubscription,
      active: false,
    });
  };

  subscribe = async () => {
    const { chatSubscription, putDoc, user } = this.props;
    if (chatSubscription) {
      await putDoc({
        ...chatSubscription,
        active: true,
      });
    } else {
      const clientChat = await this.getClientChat();
      await putDoc({
        _id: `urn.qotto.chat_subscription:${uuidv4()}`,
        type: "chat_subscription",
        chat_id: clientChat.chat_id,
        user_id: user.uuid,
        active: true,
        date_subscribed: Date.now(),
      });
    }
  };

  onMessageDelete = message => () => {
    const newDoc = {
      ...message,
      _deleted: true,
    };
    const { putDoc } = this.props;

    putDoc(newDoc);
  };

  scrollToBottom = () => {
    if (this.messagesElt) {
      this.messagesElt.scrollTop = this.messagesElt.scrollHeight;
    }
  };

  scrollToMessage = messageId => {
    const element = document.getElementById(messageId);
    if (this.messagesElt && element) {
      this.messagesElt.scrollTop = element.offsetTop - 250;
    }
  };

  componentDidMount() {
    if (!this.props.location.hash) {
      this.scrollToBottom();
    } else {
      const id = this.props.location.hash.substr(1);
      this.scrollToMessage(id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messages.length < this.props.messages.length) {
      this.scrollToBottom();
    } else {
      if (prevProps.location.hash !== this.props.location.hash) {
        const id = this.props.location.hash.substr(1);
        this.scrollToMessage(id);
      }
    }
  }

  render() {
    const { classes, messages, client, location, user, chatSubscription } = this.props;
    const { messageContent } = this.state;

    const id = location.hash.substr(1);
    const selectedMessage = messages.find(message => message.message_id === id);

    const noConversation = messages.length === 0;
    const isSubscribed = chatSubscription && chatSubscription.active;

    return (
      <DateContext.Consumer>
        {({ getDate }) => (
          <div className={classes.root}>
            {noConversation && (
              <Typography color="textSecondary">
                <T id="noMessage" values={{ client: client.name }} />
              </Typography>
            )}
            {isSubscribed ? (
              <Typography color="textSecondary">
                <T id="userIsSubscribed" />{" "}
                <Button size="small" onClick={this.unsubscribe}>
                  <T id="unsubscribe" />
                </Button>
              </Typography>
            ) : (
              <Typography color="textSecondary">
                <T id="userIsUnsubscribed" />{" "}
                <Button size="small" onClick={this.subscribe}>
                  <T id="subscribe" />
                </Button>
              </Typography>
            )}
            {!noConversation && (
              <div className={classes.messagesContainer} ref={elt => (this.messagesElt = elt)}>
                {messages.map((message, index) => (
                  <ChatMessage
                    key={message._id}
                    message={message}
                    selected={message === selectedMessage}
                    lastMessage={messages[index - 1]}
                    location={location}
                    canDelete={message.author.user_id === user.uuid}
                    onDelete={this.onMessageDelete(message)}
                  />
                ))}
              </div>
            )}
            <form className={classes.form} onSubmit={this.onSubmit(user, getDate)}>
              <TextField
                label={<T id={noConversation ? "startConversation" : "clientNewMessage"} />}
                className={classes.messageInput}
                value={messageContent}
                onChange={this.handleChange}
                onKeyDown={this.onEnterPress(user, getDate)}
                multiline
                rows="2"
              />
              <Button disabled={!messageContent.trim()} type="submit">
                <SendIcon />
              </Button>
            </form>
          </div>
        )}
      </DateContext.Consumer>
    );
  }
}

const cmp = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

const cmpByTimestamp = (messageA, messageB) => {
  if (messageA.date_synced && messageB.date_synced) {
    const diff = Math.abs(messageA.date_synced - messageB.date_synced);
    if (diff > SYNCED_SORT_THRESHOLD) {
      return cmp(messageA.date_typed, messageB.date_typed);
    }
  }
  return cmp(messageA.date_typed, messageB.date_typed);
};

ClientChat.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const ClientChatWrapper = props => (
  <UserContext.Consumer>
    {({ user }) => (
      <InstallationsContext.Consumer>
        {state => {
          const clientChat = state.clientChats.find(_ => _.client_id === props.client.zoho_id);
          let messages = [];
          let chatSubscription = null;
          if (clientChat) {
            const { chat_id } = clientChat;
            messages = state.messages
              .filter(message => message.chat_id === chat_id)
              .sort(cmpByTimestamp);
            chatSubscription = state.chatSubscriptions.find(
              _ => _.chat_id === chat_id && _.user_id === user.uuid
            );
          }

          return (
            <PouchdbContext.Consumer>
              {({ putDoc }) => (
                <ClientChat
                  user={user}
                  messages={messages}
                  clientChat={clientChat}
                  chatSubscription={chatSubscription}
                  putDoc={putDoc}
                  {...props}
                />
              )}
            </PouchdbContext.Consumer>
          );
        }}
      </InstallationsContext.Consumer>
    )}
  </UserContext.Consumer>
);

export default compose(withStyles(styles), withRouter)(ClientChatWrapper);
