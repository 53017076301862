import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { OffsetFormattedRelative } from "components";

const styles = {
  nowrap: {
    whiteSpace: "nowrap",
  },
};

const BoxRelativeExpiration = ({ expiration, classes }) => {
  const expirationDate = new Date(expiration);
  return (
    <span className={classes.nowrap}>
      <OffsetFormattedRelative value={expirationDate} />
    </span>
  );
};

BoxRelativeExpiration.propTypes = {
  expiration: PropTypes.number.isRequired,
};

export default withStyles(styles)(BoxRelativeExpiration);
