import React from "react";
import { FormattedMessage as T } from "react-intl";

import { CouchAuth } from "components";

import { Map } from "./components";

const MapScene = () => (
  <CouchAuth>
    <Map />
  </CouchAuth>
);

export default MapScene;

export const Breadcrumb = () => <T id="map" />;
