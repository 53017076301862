import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";

const styles = {};

class ColumnsSelectDialog extends React.PureComponent {
  state = {
    open: false,
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  toggleColumn = column => () => {
    const { selectedColumns } = this.props;
    if (selectedColumns.indexOf(column) !== -1) {
      this.props.onColumnsSelect(selectedColumns.filter(value => value !== column));
    } else {
      this.props.onColumnsSelect([...selectedColumns, column]);
    }
  };

  render() {
    const { selectedColumns, columns } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Tooltip title={<T id="selectColumns" />}>
          <IconButton onClick={this.toggleOpen}>
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <Dialog onClose={this.toggleOpen} open={open}>
          <DialogTitle>
            <T id="columnsOptionsTitle" />
          </DialogTitle>
          <DialogContent>
            <FormGroup>
              {Object.keys(columns).map(column => (
                <FormControlLabel
                  key={column}
                  label={columns[column].title}
                  control={
                    <Checkbox
                      checked={selectedColumns.indexOf(column) !== -1}
                      onChange={this.toggleColumn(column)}
                      disabled={
                        selectedColumns.indexOf(column) !== -1 && selectedColumns.length === 1
                      }
                    />
                  }
                />
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleOpen} color="default">
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ColumnsSelectDialog.propTypes = {
  selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onColumnsSelect: PropTypes.func.isRequired,
  columns: PropTypes.object.isRequired,
};

export default withStyles(styles)(ColumnsSelectDialog);
