import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T } from "react-intl";

import { KibanaGraph } from "components";
import { formatWh } from "utils/format";

const styles = theme => ({
  section: {
    color: theme.palette.text.secondary,
    marginBottom: "12px",
  },
  data: {
    color: theme.palette.text.primary,
  },
});

const TelemetryTab = ({ box, classes }) => (
  <div>
    {box.avg_power_7days && (
      <div className={classes.section}>
        <T
          id="energyAvgProduction"
          values={{
            production: (
              <span className={classes.data}>{formatWh(box.avg_power_7days.production)}</span>
            ),
            consumption: (
              <span className={classes.data}>{formatWh(box.avg_power_7days.consumption)}</span>
            ),
            percentage:
              Math.round(
                (box.avg_power_7days.consumption * 100) / box.avg_power_7days.production
              ) || 0,
          }}
        />
      </div>
    )}
    <KibanaGraph scSerial={box.sc_serial} />
  </div>
);

export default withStyles(styles)(TelemetryTab);
