export const makeCancelable = promise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const bufferize = (callback, minDelay, maxDelay) => {
  let queue = [];
  let timeout = null;
  let execution = Promise.resolve();
  let timeoutDate = null;
  return elts => {
    if (timeout) clearTimeout(timeout);
    queue = queue.concat(elts);
    const now = Date.now();
    let delay = minDelay;
    if (timeoutDate) {
      delay = Math.min(minDelay, maxDelay - (now - timeoutDate));
    } else {
      timeoutDate = now;
    }
    timeout = setTimeout(() => {
      const data = queue;
      queue = [];
      timeout = null;
      timeoutDate = null;
      execution = execution.then(() => callback(data));
    }, delay);
  };
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
