import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { BoxStatusIcon } from "components";
import { boxType } from "types";

import boxProperties from "data/installations/properties";

const styles = {
  root: {
    width: "48px",
    height: "48px",
    position: "relative",
  },
  iconWrapper: {
    position: "absolute",
    top: 6,
    left: 0,
    right: 0,
    textAlign: "center",
  },
};

const BoxMarker = ({ classes, box }) => (
  <div className={classes.root}>
    <svg
      width="48"
      height="48"
      stroke="black"
      strokeOpacity="0.2"
      fill={`hsl(${boxProperties.status.getValue(box) === "on" ? "120" : "0"}, 40%, 94%)`}
      fillOpacity="0.9"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"
      />
    </svg>
    <div className={classes.iconWrapper}>
      <BoxStatusIcon box={box} />
    </div>
  </div>
);

BoxMarker.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(BoxMarker);
