import React from "react";
import { withStyles } from "@material-ui/core/styles";
import earth_emoji from "twemoji/2/svg/1f30d.svg";

import { countryType } from "types";

const styles = {
  img: {
    height: "1em",
    width: "1em",
    margin: "0 .05em 0 .1em",
    verticalAlign: "-0.1em",
  },
};

const CountryFlag = ({ country, classes }) =>
  country ? (
    <img className={classes.img} src={country.flag} alt={country.code} title={country.name} />
  ) : (
    <img className={classes.img} src={earth_emoji} alt="world" />
  );

CountryFlag.propTypes = {
  country: countryType,
};

export default withStyles(styles)(CountryFlag);
