import React from "react";
import { Route, Switch } from "react-router-dom";

import { DeviceToInstallation, MessageToInstallation } from "./components";
import countries from "countries";

const countryEnum = countries.map(c => c.label).join("|");

const AppLevelRedirect = ({ children }) => (
  <Switch>
    <Route exact path={`/:country(${countryEnum})?/device/:id`} component={DeviceToInstallation} />
    <Route
      exact
      path={`/:country(${countryEnum})?/message/:message_id`}
      component={MessageToInstallation}
    />
    <Route render={() => children} />
  </Switch>
);

export default AppLevelRedirect;
