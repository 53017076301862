import React from "react";
import { NavLink } from "react-router-dom";

import { addToPath } from "countries";
import { CountryContext } from "contexts";

const stripCountryParam = path => path.replace(/\/:country\(.*\)\?/, "");

const LocalLink = ({ to, ...props }) => (
  <CountryContext.Consumer>
    {country => <NavLink to={addToPath(stripCountryParam(to), country)} {...props} />}
  </CountryContext.Consumer>
);

export default LocalLink;
