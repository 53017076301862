import React from "react";
import { withRouter } from "react-router-dom";

import { getFromPath } from "countries";

const CountryContext = React.createContext({});

export default CountryContext;

class Provider extends React.Component {
  state = {};

  static getDerivedStateFromProps = ({ location }) => ({
    country: getFromPath(location.pathname) || null,
  });

  render() {
    return (
      <CountryContext.Provider value={this.state.country}>
        {this.props.children}
      </CountryContext.Provider>
    );
  }
}

Provider = withRouter(Provider);

export { Provider };
