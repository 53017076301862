import burkina_flag from "twemoji/2/svg/1f1e7-1f1eb.svg";
import benin_flag from "twemoji/2/svg/1f1e7-1f1ef.svg";

const countries = [
  {
    label: "burkina",
    name: "Burkina Faso",
    code: "bf",
    flag: burkina_flag,
    aliases: ["burkina", "bf", "bfa", "burkinafaso", "burkina-faso"],
    zoho_project: "burkina",
  },
  {
    label: "benin",
    name: "Bénin",
    code: "bj",
    flag: benin_flag,
    aliases: ["benin", "ben", "bj"],
    zoho_project: "benin",
  },
];

export const addToPath = (path, country) => {
  const countryPrefix = country && country.label ? "/" + country.label : "";
  const sanitizedPath = path.replace(/:country(.*)\?\//, "");
  const [, countryLabel, ...rest] = sanitizedPath.split("/");
  const hasCountry = countries.find(c => c.label === countryLabel);
  if (hasCountry) {
    return [countryPrefix, ...rest].join("/");
  } else {
    return countryPrefix + sanitizedPath;
  }
};

export const getFromPath = (path, country) => {
  const [, countryLabel] = path.split("/");
  return countries.find(c => c.label === countryLabel);
};

export const countryFromLabel = label => countries.find(_ => _.label === label);

export default countries;
