import React, { Fragment } from "react";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HelpIcon from "@material-ui/icons/Help";
import ReactMarkdown from "react-markdown";

import { boxType } from "types";
import { BoxFollowupCategory } from "components";

const styles = {
  helpButton: {
    padding: 0,
    verticalAlign: "top",
    fontSize: "inherit",
  },
};

const explanationMarkdown = `
5 catégories de relance sont définies à ce jour :

- **1** : ne nécessite aucune action
- **2** : 1ère relance réalisée par le contact center pour que le client s'engage à payer le montant dû
- **3** : 2ème relance effectuée par le contact center, si le client n'a pas encore régularisé sa situation
- **4** : Création de la fiche "relance client" par le CC (sans en informer forcément le client )et transfert du dossier client au TL
- **5** : 3ème relance par le TL avec menace de désinstallation
- 6 : Désinstallation

Ces catégories sont définies d'après le tableau suivant, *i* étant le nombre de jours d'inactivation consécutifs :

[Calcul de la catégorie de relance](https://www.notion.so/d868280635c44ce4b0ea57b685e8e2ed)
`;

class FollowupCategoryInfo extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleDialog = () => {
    this.setState({
      helpDialogOpen: !this.state.helpDialogOpen,
    });
  };

  render() {
    const { classes, box } = this.props;
    const { helpDialogOpen } = this.state;

    return (
      <Fragment>
        <Dialog open={helpDialogOpen} onClose={this.toggleDialog}>
          <DialogTitle>
            <T id="followupCategory" />
          </DialogTitle>
          <DialogContent>
            <ReactMarkdown linkTarget="_blank" source={explanationMarkdown} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>
              <T id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        <span>
          <T id="followupCategoryInfo" />{" "}
          <strong>
            <BoxFollowupCategory box={box} />
          </strong>{" "}
          <IconButton fontSize="inherit" className={classes.helpButton} onClick={this.toggleDialog}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Fragment>
    );
  }
}

FollowupCategoryInfo.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(FollowupCategoryInfo);
