import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T } from "react-intl";

import { BoxLabel, BoxTextStatus, BoxDailyConsumption, BoxDateTime } from "components";
import { boxType } from "types";

const styles = {
  nowrap: {
    whiteSpace: "nowrap",
  },
};

const BoxSubtitle = ({ box, classes }) => (
  <span>
    Box&nbsp;
    <BoxLabel box={box} />
    {", "}
    {box.avg_power_7days && (
      <Fragment>
        <T id="consumption" />
        &nbsp;
        <b>
          <BoxDailyConsumption box={box} />
        </b>
        ,
      </Fragment>
    )}
    {box.expiration_timestamp && (
      <Fragment>
        <br />
        <BoxTextStatus expiration={box.expiration_timestamp} determinant />
        &nbsp;
        <b>
          <BoxDateTime value={box.expiration_timestamp} />
        </b>
      </Fragment>
    )}
  </span>
);

BoxSubtitle.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(BoxSubtitle);
