import React from "react";
import PropTypes from "prop-types";

import { Filter } from "components";
import boxProperties, { redirect } from "data/installations/properties";
import { getTips, filterRedirect, operatorRedirect } from "utils/filter";

const sortOptions = [
  "box",
  "client",
  "expiration",
  "firstActivationDate",
  "lastCommunication",
  "dailyProduction",
  "dailyConsumption",
  "consumptionPercentage",
  "activationRate",
  "lastClientCall",
  "clientCallback",
  "financialCategory",
  "followupCategory",
  "installationDate",
  "installationDays",
  "cheatScore",
];

const filterOptions = [
  "client",
  "box",
  "sc",
  "bmsBrand:Biolite",
  "bmsBrand:Victron",
  "expiration",
  "inactiveDays.gt",
  "country",
  "status:on",
  "status:off",
  "status:unsynchronized",
  "status:permanent",
  "contractState:finished",
  "contractState:fullyPaid",
  "contractState:finishedSoon",
  "contractState:notFinishedSoon",
];

const BoxFilter = props => (
  <Filter
    filterOptions={filterOptions}
    sortOptions={sortOptions}
    getTips={getTips(boxProperties)}
    onInputRedirect={filterRedirect(redirect)}
    onMountRedirect={inputValue => operatorRedirect(filterRedirect(redirect)(inputValue))}
    {...props}
  />
);

BoxFilter.propTypes = {
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  invertSortOrder: PropTypes.func,
  sort: PropTypes.func,
};

export default BoxFilter;

/*

Fonctionnement des filtres




*/
