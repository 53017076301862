const splitAndJoin = (splitChar, lambda) => string =>
  string
    .split(splitChar)
    .map(lambda)
    .join(splitChar);

const OR_SEPARATOR = " or ";
const AND_SEPARATOR = " and ";
const EQUAL_SEPARATOR = ":";
const OPERATOR_SEPARATOR = ".";

// replace " " and "|" with new operators
export const operatorRedirect = filterInput =>
  filterInput
    .split(AND_SEPARATOR)
    .join(" ")
    .split(OR_SEPARATOR)
    .join("|")
    .split(" ")
    .join(AND_SEPARATOR)
    .split("|")
    .join(OR_SEPARATOR);

export const filterRedirect = redirect =>
  splitAndJoin(
    OR_SEPARATOR,
    splitAndJoin(AND_SEPARATOR, andElement => {
      const [key, ...rem] = andElement.split(EQUAL_SEPARATOR);
      const [operatorName, ...funcs] = key.split(OPERATOR_SEPARATOR);
      const redirectedOperator = redirect(operatorName);
      if (redirectedOperator === null) {
        return andElement;
      }
      return [[redirectedOperator, ...funcs].join(OPERATOR_SEPARATOR), ...rem].join(
        EQUAL_SEPARATOR
      );
    })
  );

const defaultOperand = operator => operator.equals || operator.contains || null;

export const filter = (properties, defaultProperty) => filterInput => entity => {
  const orElements = filterInput.split(OR_SEPARATOR);
  return orElements.some(orElement => {
    const andElements = orElement.split(AND_SEPARATOR);
    return andElements.every(andElement => {
      const [key, ...rem] = andElement.split(EQUAL_SEPARATOR);
      const [operatorName, ...funcs] = key.split(OPERATOR_SEPARATOR);
      if (rem.length === 0) {
        //no operator
        const entityValue = defaultProperty.getValue(entity);
        return defaultOperand(defaultProperty.operator)(key, entityValue);
      } else if (properties[operatorName] === undefined) {
        return true; //operator is ignored
      } else {
        const value = rem.join(EQUAL_SEPARATOR).replace("_", " ");
        if (funcs.length === 0) {
          //no func
          const property = properties[operatorName];
          if (!defaultOperand(property.operator)) {
            //no default func
            return true;
          } else {
            const entityValue = property.getValue(entity);
            return defaultOperand(property.operator)(value, entityValue);
          }
        } else {
          const func = funcs.join(OPERATOR_SEPARATOR);
          const property = properties[operatorName];
          const operator = property.operator;
          if (operator[func] === undefined) {
            return true; //func is ignored
          } else {
            const entityValue = property.getValue(entity);
            return operator[func](value, entityValue);
          }
        }
      }
    });
  });
};

const defaultSort = (a, b) => {
  if (a === b) {
    return 0;
  } else if (a === null) {
    return -1;
  } else if (b === null) {
    return 1;
  }
  return a < b ? -1 : 1;
};

export const sort = properties => (sortProperty, order) => (a, b) => {
  const property = properties[sortProperty];
  const getValue = property.getValue;
  const sort = property.sort || defaultSort;
  const comp = sort(getValue(a), getValue(b));
  return order === "asc" ? comp : -comp;
};

export const getTips = properties => filterInput => {
  const lastElement = filterInput
    .split(OR_SEPARATOR)
    .join(AND_SEPARATOR)
    .split(AND_SEPARATOR)
    .reverse()[0];
  if (lastElement) {
    const [key, ...rem] = lastElement.split(EQUAL_SEPARATOR);
    const [operatorName, ...funcs] = key.split(OPERATOR_SEPARATOR);
    const property = properties[operatorName];
    if (rem.length === 0 && funcs.length === 0) {
      //no operator
      const matchingOperators = Object.keys(properties).filter(operator =>
        operator.startsWith(lastElement)
      );
      return matchingOperators.map(
        o =>
          o.replace(new RegExp("^" + lastElement), "") +
          (defaultOperand(properties[o].operator) ? EQUAL_SEPARATOR : OPERATOR_SEPARATOR)
      );
    } else if (property && property.operator) {
      const value = rem.join(EQUAL_SEPARATOR).replace("_", " ");
      const { tips, operator } = property;
      if (funcs.length === 0) {
        //no func
        if (tips) {
          const matchingTips = tips.filter(tip => tip.startsWith(value));
          return matchingTips.map(matchingTip => matchingTip.replace(new RegExp("^" + value), ""));
        }
      } else if (rem.length === 0) {
        const func = funcs.join(OPERATOR_SEPARATOR);
        const matchingFuncs = Object.keys(operator).filter(f => f.startsWith(func));
        return matchingFuncs.map(
          matchingFunc => matchingFunc.replace(new RegExp("^" + func), "") + EQUAL_SEPARATOR
        );
      }
    }
  }
  return [];
};
