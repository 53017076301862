import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { BoxStatusIcon, BoxTitle, BoxSubtitle, ExternalButton, LocalLink } from "components";
import { boxType } from "types";
import { googleMaps } from "utils/externalServices";

const styles = {
  root: {
    textAlign: "center",
  },
  "@global .leaflet-container a": {
    color: "initial",
  },
};

const BoxPopup = ({ classes, box }) => (
  <div className={classes.root}>
    <ListItem
      button
      component={LocalLink}
      to={`/map/${box.contract.code}`}
      className={classes.listItem}
    >
      <BoxStatusIcon box={box} />
      <ListItemText primary={<BoxTitle box={box} />} secondary={<BoxSubtitle box={box} />} />
    </ListItem>
    {box.gps_coordinates ? <ExternalButton service={googleMaps} args={box.gps_coordinates} /> : ''}
  </div>
);

BoxPopup.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(BoxPopup);
