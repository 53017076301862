import React from "react";
import { Redirect, withRouter } from "react-router-dom";

import { UserContext } from "contexts";

const PUSH_SUBSCRIPTION_PUBLIC_KEY = process.env.REACT_APP_PUSH_SUBSCRIPTION_PUBLIC_KEY;

const PUSH_SUBSCRIPTION_CALLBACK_ENDPOINT =
  process.env.REACT_APP_PUSH_SUBSCRIPTION_CALLBACK_ENDPOINT;

const urlB64ToUint8Array = base64String => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const SERVER_KEY = urlB64ToUint8Array(PUSH_SUBSCRIPTION_PUBLIC_KEY);

const SWContext = React.createContext();

export default SWContext;

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: null,
      registration: null,
      redirectUri: null,
      unsubscribe: this.unsubscribe,
      newVersion: false,
      update: this.update,
      ignore: this.ignore,
      reloadAllOtherClients: this.reloadAllOtherClients,
    };
  }

  unsubscribe = async () => {
    const { subscription, registration } = this.state;
    if (registration && subscription) {
      const sw_scope = registration.scope;
      await fetch(PUSH_SUBSCRIPTION_CALLBACK_ENDPOINT, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription, sw_scope }),
      }).catch(console.warn);
    }
  };

  onUserSubscribe = (subscription, sw_scope) => async () => {
    try {
      const response = await fetch(PUSH_SUBSCRIPTION_CALLBACK_ENDPOINT, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription, sw_scope }),
      });
      const { ok } = await response.json();
      if (!ok) {
        throw Error("Invalid response");
      }
      window.removeEventListener("online", this.subscribeCall);
    } catch (error) {
      console.log("couldn't send subscription to the server. Retrying once we go online");
      window.addEventListener("online", this.subscribeCall);
    }
  };

  subscribeUser = async registration => {
    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: SERVER_KEY,
      });
      //console.log("User has just subscribed.", { subscription });
      this.subscribeCall = this.onUserSubscribe(subscription, registration.scope);
      await this.subscribeCall();
      this.setState({ subscription });
    } catch (error) {
      console.log("Failed to subscribe the user: ", error);
    }
  };

  onServiceWorkerMessage = async data => {
    switch (data.type) {
      case "RELOAD":
        window.location.reload();
        break;
      case "NAVIGATE":
        const redirectUri = data.payload;
        this.setState({
          redirectUri,
        });
        break;
      default:
        break; //console.log("Received message from SW:", data);
    }
  };

  update = () => {
    const { registration } = this.state;
    if (registration && registration.waiting) {
      // A SW is in waiting state. Let's activate it and reload all clients of old SW.
      registration.waiting.postMessage({
        type: "FORCE_ACTIVATE",
      });
      this.setState({
        newVersion: false,
      });
    } else {
      console.error("Cannot update. No SW is currently in waiting state");
      // fallback to old behaviour
      window && window.location.reload();
    }
  };

  reloadAllOtherClients = () => {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: "RELOAD_ALL_OTHER_CLIENTS",
      });
    } else {
      console.error("Cannot reload clients. No SW is currently installed");
    }
  };

  ignore = () => {
    this.setState({
      newVersion: false,
    });
  };

  onSWUpdate = () => {
    this.setState({
      newVersion: true,
    });
  };

  async componentDidMount() {
    const isProd = process.env.NODE_ENV === "production";
    const isBrowserCompatible = "serviceWorker" in navigator;
    const isLoggedIn = Boolean(this.props.user);
    if (isProd && isBrowserCompatible) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        navigator.serviceWorker.onmessage = e => this.onServiceWorkerMessage(e.data);
        this.setState({ registration });
        if (isLoggedIn) {
          await this.subscribeUser(registration);
        }
      }
    }
    window.addEventListener("serviceworkerupdate", this.onSWUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.subscribeCall);
    window.removeEventListener("serviceworkerupdate", this.onSWUpdate);
  }

  render() {
    const { redirectUri } = this.state;

    return (
      <SWContext.Provider value={this.state}>
        {redirectUri && <Redirect to={redirectUri} />}
        {this.props.children}
      </SWContext.Provider>
    );
  }
}

let ProviderWrapper = props => (
  <UserContext.Consumer>{({ user }) => <Provider user={user} {...props} />}</UserContext.Consumer>
);

ProviderWrapper = withRouter(ProviderWrapper);

export { ProviderWrapper as Provider };
