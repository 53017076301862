import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginIcon from "mdi-material-ui/Login";
import LogoutIcon from "mdi-material-ui/Logout";

import { UserContext, PouchdbContext } from "contexts";

const styles = theme => ({
  root: {
    margin: "8px",
  },
  progressContainer: {
    textAlign: "center",
    flex: 1,
  },
  progress: {
    marginTop: "35vh",
  },
  message: {
    color: theme.palette.text.secondary,
  },
});

let CouchAuth = ({
  waitUntil,
  children,
  location,
  classes,
  token,
  error,
  loading,
  replicating,
  getLoginUrl,
  getLogoutUrl,
}) => {
  if (loading || !waitUntil) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress className={classes.progress} />
        {replicating && (
          <div className={classes.message}>
            <T id="replicatingDatabase" />
          </div>
        )}
      </div>
    );
  } else if (error && error === "FORBIDDEN") {
    return (
      <Card className={classes.root}>
        <CardContent>{token ? <T id="unauthorized" /> : <T id="pleaseLogin" />}</CardContent>
        <CardActions>
          {token ? (
            <Button href={getLogoutUrl(location.pathname)} color="primary">
              <LogoutIcon />
              &nbsp;
              <T id="signinAgain" />
            </Button>
          ) : (
            <Button href={getLoginUrl(location.pathname)} color="primary">
              <LoginIcon />
              &nbsp;
              <T id="signin" />
            </Button>
          )}
        </CardActions>
      </Card>
    );
  } else if (error && error === "QUOTA_TOO_SMALL") {
    return (
      <Card className={classes.root}>
        <CardContent>
          <T id="quotaTooSmall" />
        </CardContent>
      </Card>
    );
  } else if (error && error === "SERVICEWORKER_NOT_SUPPORTED") {
    return (
      <Card className={classes.root}>
        <CardContent>
          <T id="serviceWorkerNotSupported" />
        </CardContent>
      </Card>
    );
  } else if (error) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <T id="unknownCouchdbError" values={{ errorMessage: error }} />
        </CardContent>
      </Card>
    );
  }
  return children;
};

CouchAuth.propTypes = {
  auth: PropTypes.object,
  replicating: PropTypes.bool.isRequired,
  waitUntil: PropTypes.bool,
  children: PropTypes.node,
};

CouchAuth.defaultProps = {
  waitUntil: true,
};

const CouchAuthWrapper = props => (
  <UserContext.Consumer>
    {({ token, getLoginUrl, getLogoutUrl }) => (
      <PouchdbContext.Consumer>
        {({ error, loading, replicating }) => (
          <CouchAuth
            token={token}
            getLoginUrl={getLoginUrl}
            getLogoutUrl={getLogoutUrl}
            error={error}
            loading={loading}
            replicating={replicating}
            {...props}
          />
        )}
      </PouchdbContext.Consumer>
    )}
  </UserContext.Consumer>
);

export default compose(withStyles(styles), withRouter)(CouchAuthWrapper);
