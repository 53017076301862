import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage as T, FormattedDate } from "react-intl";

import { OffsetFormattedRelative } from "components";

const styles = {};

const THRESHOLD = 15 * 24 * 3600 * 1000; // 15 days

const CustomFormattedDate = ({ classes, value }) => {
  const now = Date.now();

  const olderThanThreshold = Math.abs(now - value.getTime()) > THRESHOLD;

  return (
    <Tooltip
      title={
        <FormattedDate
          value={value}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="2-digit"
          minute="2-digit"
        />
      }
    >
      <span>
        {olderThanThreshold ? (
          <Fragment>
            <T id="expirationDeterminant" />{" "}
            <FormattedDate value={value} year="numeric" month="long" day="numeric" />
          </Fragment>
        ) : (
          <OffsetFormattedRelative value={value} />
        )}
      </span>
    </Tooltip>
  );
};

CustomFormattedDate.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
};

export default withStyles(styles)(CustomFormattedDate);
