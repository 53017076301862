import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import LaunchIcon from "@material-ui/icons/Launch";
import { FormattedMessage as T } from "react-intl";

import { ExternalMenuItem } from "components";
import { boxType } from "types";
import {
  kibana,
  gateway,
  zohoClient,
  zohoContract,
  zohoInstallation,
  googleMaps,
} from "utils/externalServices";

const styles = {};

class ExternalLinks extends PureComponent {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { box } = this.props;
    const { anchorEl } = this.state;
    try{
      var has_smartcard = (box.kit.bms.smartcard.serial_number)!=='undefined' ? true : false;
    }
    catch (TypeError){
      has_smartcard = false;
    }
    return (
      <span>
        <Button
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <T id="externalLinks" /> <LaunchIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {has_smartcard ? <ExternalMenuItem service={gateway} args={{ scSerial: box.kit.bms.smartcard.serial_number }} /> : ''}
          {has_smartcard ? <ExternalMenuItem service={kibana} args={{ scSerial: box.kit.bms.smartcard.serial_number }} /> : ''}
        <ExternalMenuItem
            service={zohoClient}
            args={{
              id: box.client.zoho_id,
              project: box.zoho_project,
            }}
          />
          <ExternalMenuItem
            service={zohoContract}
            args={{
              id: box.contract.zoho_id,
              project: box.zoho_project,
            }}
          />
          {box.zoho_id && (
            <ExternalMenuItem
              service={zohoInstallation}
              args={{
                id: box.zoho_id,
                project: box.zoho_project,
              }}
            />
          )}
          {box.gps_coordinates && (
            <ExternalMenuItem service={googleMaps} args={box.gps_coordinates} />
          )}
        </Menu>
      </span>
    );
  }
}

ExternalLinks.propTypes = {
  box: boxType.isRequired,
};

export default withStyles(styles)(ExternalLinks);
