import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { externalServiceType } from "types";

const ExternalMenuItem = ({ service, args, ...props }) => {
  const Icon = service.icon;

  return (
    <MenuItem href={service.uri(args)} component="a" target="_blank" {...props}>
      <ListItemIcon style={{ color: service.color }}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={service.label} />
    </MenuItem>
  );
};

ExternalMenuItem.propTypes = {
  service: externalServiceType.isRequired,
  args: PropTypes.object.isRequired,
};

export default ExternalMenuItem;
