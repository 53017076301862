import queryString from "query-string";

import * as conf from "./config";

const DESIGN_DOC = process.env.REACT_APP_COUCHDB_DESIGN_DOC;

const RO_VIEW = `${DESIGN_DOC}/allRODocs`;
const RW_VIEW = `${DESIGN_DOC}/allRWDocsByUserId`;

const safeFetch = (...args) =>
  fetch(...args).then(response => {
    const { ok, status } = response;
    if (!ok) {
      throw Error(`Request failed with status ${status}`);
    }
    return response;
  });

async function* getLinesReader(streamReader) {
  const decoder = new TextDecoder("utf-8");
  let partialData = "";
  do {
    const chunk = await streamReader.read();
    if (chunk.value) {
      partialData += decoder.decode(chunk.value);
      const lines = partialData.split("\n");
      partialData = lines.pop(); // if last line is complete, partialData is reinitialized. else, it takes last line partial value
      // if line is complete but is missing a newline, it is put in partialData and is processed next time we receive a newline.
      yield lines;
    }
    if (chunk.done) {
      return;
    }
  } while (true);
}

export const readOnlyChanges = (remoteDB, since, userId, onChanges) => {
  const searchParams = queryString.stringify({
    ...conf.readOnlyLiveChangesOpts,
    since,
  });

  const fetchParams = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      selector: conf.roSelector(userId),
    }),
  };

  const url = `${remoteDB.name}/_changes?${searchParams}`;

  let streamReader = null;
  let canceled = false;
  const cancel = () => {
    if (streamReader !== null) {
      streamReader.cancel();
    }
    canceled = true;
  };

  const promise = safeFetch(url, fetchParams).then(async response => {
    streamReader = response.body.getReader();
    if (canceled) {
      streamReader.cancel();
      return;
    }
    const linesReader = getLinesReader(streamReader);
    let stop = false;
    while (!stop) {
      const { done, value: lines } = await linesReader.next();
      if (lines) {
        const changes = lines
          .filter(line => line !== "")
          .map(JSON.parse)
          .filter(({ doc }) => doc && !doc._id.startsWith("_design/"));
        if (changes.length > 0) {
          await onChanges(changes);
        }
      }
      stop = done;
    }
  });

  return {
    cancel,
    promise,
  };
};

export const applyReadOnlyChanges = async (localDB, changes) => {
  const readOnlyUpdateSeq = await localDB.get("_local/readOnlyUpdateSeq");
  let { update_seq } = readOnlyUpdateSeq;
  const docs = changes.map(_ => _.doc);
  await localDB.bulkDocs(docs, { new_edits: false });
  await localDB.put({
    ...readOnlyUpdateSeq,
    update_seq,
  });
};

const isReadOnlyDocument = (userId, document) => {
  // Chat messages from the user are not read-only. We need to filter them out.
  if (document.type && document.type === "chat_message") {
    if (document.author && document.author.user_id) {
      if (document.author.user_id === userId) {
        return false;
      }
    }
  }
  return true;
};

const row2document = ({ id, value }) => {
  return {
    _id: id,
    ...value,
  };
};

export const initialReadOnlyView = async (remoteDB, userId) => {
  const result = await remoteDB.query(RO_VIEW, {
    update_seq: true,
    stale: "update_after",
  });

  const documentsStore = {};
  for (const row of result.rows) {
    if (isReadOnlyDocument(userId, row.value)) {
      documentsStore[row.id] = row2document(row);
    }
  }

  return {
    updateSeq: result.update_seq,
    documentsCount: result.total_rows,
    documentsStore: documentsStore,
  };
};

export const initialReadWriteView = async (remoteDB, userId) => {
  const result = await remoteDB.query(RW_VIEW, {
    keys: [0, userId],
    stale: "update_after",
  });

  const documentsStore = {};
  for (const row of result.rows) {
    documentsStore[row.id] = row2document(row);
  }

  return {
    documentsStore: documentsStore,
  };
};

export const catchUpReadOnlyChanges = async (localDB, remoteDB, userId) => {
  const { update_seq } = await localDB.get("_local/readOnlyUpdateSeq");
  const { results } = await remoteDB.changes({
    filter: "_selector",
    selector: conf.roSelector(userId),
    since: update_seq,
    include_docs: true,
    batch_size: Number.MAX_SAFE_INTEGER,
  });
  await applyReadOnlyChanges(localDB, results);
};

export const readWriteReplication = async (localDB, remoteDB, userId) => {
  await localDB.replicate.from(
    remoteDB,
    conf.readWriteReplicationOpts({ userId, checkpoint: "target" })
  );
};
