import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { FormattedMessage as T } from "react-intl";

import { kibana } from "utils/externalServices";

const styles = theme => ({
  kibanaFrame: {
    border: "none",
    width: "100%",
    height: "920px",
    minWidth: "720px",
  },
  iframeWrapper: {
    overflowX: "auto",
  },
});

const fromDate = date => date.toISOString().split("T")[0];

const toDate = isoString => {
  const date = new Date(isoString);
  const now = new Date();
  date.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
  return date;
};

const hasScreenOrientationFeature = Boolean(
  window && window.screen && window.screen.orientation && window.screen.orientation.lock
);

const now = new Date();

const tabs = [
  {
    label: "last7Days",
    minDate: new Date(new Date().setDate(now.getDate() - 7)),
  },
  {
    label: "last30Days",
    minDate: new Date(new Date().setMonth(now.getMonth() - 1)),
  },
  {
    label: "last6Months",
    minDate: new Date(new Date().setMonth(now.getMonth() - 6)),
  },
];

class KibanaGraph extends React.PureComponent {
  constructor(props) {
    super(props);

    const defaultTab = 0; // 7 days ago

    this.state = {
      minDate: fromDate(tabs[defaultTab].minDate),
      maxDate: fromDate(now),
      tab: defaultTab,
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleTabChange = (e, tab) => {
    this.setState({
      tab,
      minDate: fromDate(tabs[tab].minDate),
      maxDate: fromDate(now),
    });
  };

  toggleOrientationMode = () => {
    this.iframeWrapperElt.webkitRequestFullscreen();
    window.screen.orientation.lock("landscape-primary").catch(console.error);
  };

  render() {
    const { scSerial, classes } = this.props;
    const { minDate, maxDate, tab } = this.state;

    return (
      <div>
        <TextField
          label={<T id={minDate ? "from" : "invalidDate"} />}
          error={!minDate}
          name="minDate"
          type="date"
          value={minDate}
          inputProps={{
            max: maxDate,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={this.handleChange}
        />
        <TextField
          label={<T id={maxDate ? "to" : "invalidDate"} />}
          error={!maxDate}
          name="maxDate"
          type="date"
          value={maxDate}
          inputProps={{
            min: minDate,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={this.handleChange}
        />
        {hasScreenOrientationFeature && (
          <Hidden mdUp>
            <IconButton onClick={this.toggleOrientationMode}>
              <FullscreenIcon />
            </IconButton>
          </Hidden>
        )}
        <Tabs
          value={tab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
        >
          {tabs.map(tab => (
            <Tab key={tab.label} label={<T id={tab.label} />} />
          ))}
        </Tabs>
        {minDate && maxDate && (
          <div className={classes.iframeWrapper} ref={elt => (this.iframeWrapperElt = elt)}>
            <iframe
              src={kibana.iframe({
                scSerial,
                minDate: toDate(minDate),
                maxDate: toDate(maxDate),
              })}
              title="kibana"
              className={classes.kibanaFrame}
              frameBorder="0"
            />
          </div>
        )}
      </div>
    );
  }
}

KibanaGraph.propTypes = {
  scSerial: PropTypes.string.isRequired,
  login: PropTypes.string,
};

export default withStyles(styles)(KibanaGraph);
