import React from "react";

import { LocalRedirect } from "components";
import { AppLevelRedirect } from "scenes";

const NoMatch = () => (
  <AppLevelRedirect>
    <LocalRedirect to="/" />;
  </AppLevelRedirect>
);

export default NoMatch;
