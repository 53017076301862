import React from "react";
import { LocalRedirect, CouchAuth } from "components";
import { InstallationsContext } from "contexts";

const MessageToInstallation = ({ match }) => (
  <CouchAuth>
    <InstallationsContext.Consumer>
      {({ installations, messages, clientChats }) => {
        const { message_id } = match.params;
        const message = messages.find(_ => _.message_id === message_id);
        const clientChat = message && clientChats.find(_ => _.chat_id === message.chat_id);
        const installation =
          clientChat && installations.find(_ => _.client.zoho_id === clientChat.client_id);
        console.log({ message, clientChat, installation });
        if (!installation || !message) {
          return null;
        }
        return (
          <LocalRedirect
            to={`/${installation.contract.code}?show=comments#${message.message_id}`}
          />
        );
      }}
    </InstallationsContext.Consumer>
  </CouchAuth>
);

export default MessageToInstallation;
