import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { injectIntl } from "react-intl";
import Shell from "mui-shell";

import {
  Events,
  AppBarMenu,
  AppTitle,
  DrawerFooter,
  TopLevelRedirect,
  AppLevelRedirect,
  BreadcrumbHelp,
} from "scenes";
import { LocalLink } from "components";
import routes from "routes";

const Layout = ({ intl }) => {
  const t = id => intl.formatMessage({ id });

  return (
    <TopLevelRedirect>
      <CssBaseline />
      <Events />
      <Shell
        routes={routes}
        documentTitleFormatter={route => `${t(route.label)} - ${t("appTitle")}`}
        menuLabelFormatter={route => t(route.label)}
        menuLogoSrc={process.env.PUBLIC_URL + "/logos/256_bright_margin.png"}
        breadcrumbFormatter={route => t(route.label)}
        rightBreadcrumbRender={route => <BreadcrumbHelp route={route} />}
        leftAppBarComponent={<AppTitle />}
        rightAppBarComponent={<AppBarMenu />}
        linkFormatter={LocalLink}
        drawerFooter={<DrawerFooter />}
      >
        <AppLevelRedirect />
      </Shell>
    </TopLevelRedirect>
  );
};

export default compose(withRouter, injectIntl)(Layout);
