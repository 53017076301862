import React from "react";
import PouchDB from "pouchdb";

import { bufferize, sleep } from "utils/methods";
import { UserContext } from "../";

import { authenticate, getAuthInfo, logOut } from "./auth";
import requestIfMainTab from "./requestIfMainTab";
import {
  applyReadOnlyChanges,
  readWriteReplication,
  readOnlyChanges,
  initialReadOnlyView,
  initialReadWriteView,
} from "./sync";
import * as conf from "./config";
const MINIMUM_NEEDED_STORAGE = 80 * 1e6; // 80 MB
const STORAGE_USAGE_ALERT_THRESHOLD = 0.9; // 90%
const LOCAL_DB_CHANGES_BUFFER_TIMEOUT = 200; //ms
const CHANGES_MAX_TIMEOUT = 10000; //ms
const REMOTE_DB_CHANGES_BUFFER_TIMEOUT = 200; //ms
const READ_ONLY_LIVE_CHANGES_RETRY_DELAY = 5000; //ms
const INITIAL_REPLICATION_ERROR_DELAY = 5000; //delay (in ms) before retrying initial replication
const WATCH_MAIN_TAB_TIMER = 10 * 1000; // 10,000 milliseconds = 10 seconds.
const LOCAL_DB_NAME = process.env.REACT_APP_COUCHDB_DB_NAME;
const REMOTE_SERVER = process.env.REACT_APP_COUCHDB_SERVER;
const ENABLE_BACKGROUND_SYNC = process.env.REACT_APP_ENABLE_BACKGROUND_SYNC === "true";
let REMOTE_DB_PATH = REMOTE_SERVER + LOCAL_DB_NAME;
let dataFromLocalJson = null;

if (REMOTE_DB_PATH.startsWith("/")) {
  REMOTE_DB_PATH = window.location.origin + REMOTE_DB_PATH;
}

try {
  dataFromLocalJson = require('local-data.json');
} catch (e) {}

const PouchdbContext = React.createContext();

export default PouchdbContext;

const isQuotaTooSmall = async () => {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    const { quota } = await navigator.storage.estimate();
    if (quota < MINIMUM_NEEDED_STORAGE) {
      return true;
    }
  }
  return false;
};

const isStorageUsageTooHigh = async () => {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    const { usage, quota } = await navigator.storage.estimate();
    if (usage / quota > STORAGE_USAGE_ALERT_THRESHOLD) {
      return true;
    }
  }
  return false;
};

const iso2timestamp = (iso_date) => {
  const native_date = new Date(iso_date);
  const ts_date = native_date.getTime();
  return ts_date;
};

const translateDoc = doc => {
  if (doc.type !== "installation") {
    return doc;
  }
  if (!doc.version || doc.version === 1) {
    return doc;
  }
  const translated_doc = {};
  translated_doc._id = doc._id;
  translated_doc.type = doc.type;
  translated_doc.zoho_id = doc.zoho_id;
  translated_doc.installation_date = doc.installation_date;
  translated_doc.box_associated_date = null;
  if (doc.kit_associated_date){
    translated_doc.box_associated_date = doc.kit_associated_date;
  }
  else {
    translated_doc.box_associated_date = doc.installation_date;
  }
  //I'm not sure about the following field, it's not used besides tests and its not genrated in the aidp
  translated_doc.created_timestamp = Date.now();
  translated_doc.gps_coordinates = null;
  if (doc.geolocation && doc.geolocation.lat && doc.geolocation.lon){
    translated_doc.gps_coordinates = {
      lat: doc.geolocation.lat,
      lon: doc.geolocation.lon,
    }
  }
  translated_doc.installation_id = null;
  translated_doc.expiration_timestamp = null;
  translated_doc.zoho_project = null;
  translated_doc.client = {};
  translated_doc.contract = {};
  translated_doc.credit_info = null;
  translated_doc.first_activation_date = null;
  if (doc.contract){
    translated_doc.installation_id = doc.contract.code + "-0000";
    translated_doc.expiration_timestamp = doc.contract.activation_end_date ? iso2timestamp(doc.contract.activation_end_date) : null;
    if (doc.contract.country_code === 'bf'){
      translated_doc.zoho_project = "burkina";
    }
    else if (doc.contract.country_code === 'bj'){
      translated_doc.zoho_project = "benin";
    }
    translated_doc.contract.code = doc.contract.code;
    translated_doc.contract.zoho_id = doc.contract.zoho_id;
    translated_doc.contract.finished_soon = doc.contract.finished_soon;
    translated_doc.contract.fully_paid = doc.contract.fully_paid;
    translated_doc.contract.finished = doc.contract.finished;
    if (doc.contract.customer){
      translated_doc.client = doc.contract.customer;
    }
    if (doc.contract.credit_info){
      translated_doc.credit_info = doc.contract.credit_info;
    }
    if (doc.contract.activations_summary){
      translated_doc.activations_summary = doc.contract.activations_summary;
    }
    translated_doc.first_activation_date = doc.contract.first_activation_date;
  }
  translated_doc.cheat_score = doc.cheat_score;
  translated_doc.kit = {};
  translated_doc.confirmed_expiration_timestamp = null;
  translated_doc.avg_power_7days = {};
  translated_doc.bms_label = null;
  translated_doc.sc_label = null;
  translated_doc.sc_serial = null;
  translated_doc.last_communication_timestamp = null;
  if (doc.kit){
    translated_doc.kit = doc.kit;
    //Since the event to produce confirmed expiration date is not available yet, we're using this field.
    if (doc.kit.activation_end_date) {
      translated_doc.confirmed_expiration_timestamp = iso2timestamp(doc.kit.activation_end_date);
    }
    if (doc.kit.bms){
      translated_doc.avg_power_7days = doc.kit.bms.avg_power_7days;
      translated_doc.bms_label = doc.kit.bms.serial_number;
      if (doc.kit.bms.smartcard){
        translated_doc.sc_label = doc.kit.bms.smartcard.serial_number.slice(-3);
        translated_doc.sc_serial = doc.kit.bms.smartcard.serial_number;
        translated_doc.last_communication_timestamp = doc.kit.bms.smartcard.last_communication_timestamp;
      }
    }
  }
  return translated_doc;
};

const extractDocs = ({ rows }) => rows.map(row => translateDoc(row.doc));

const reduceById = docs => {
  const res = {};
  for (const doc of docs) {
    res[doc._id] = doc;
  }
  return res;
};

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.database = null;
    this.remoteDatabase = null;
    this.changes = null;
    this.readWriteSync = null;
    this.isMainTab = null;
    this.mainTabTimer = null;

    // buffer for document updates in memory. It prevents the UI from being blocked when many changes occurs at the same time
    this.bufferedPutDocsInMemory = bufferize(
      this.putDocsInMemory,
      LOCAL_DB_CHANGES_BUFFER_TIMEOUT,
      CHANGES_MAX_TIMEOUT
    );

    // buffer for document updates in database. It prevents conflicts when multiple changes occurs at the same time on the same document
    this.bufferedOnReadOnlyChanges = bufferize(
      this.onReadOnlyChanges,
      REMOTE_DB_CHANGES_BUFFER_TIMEOUT,
      CHANGES_MAX_TIMEOUT
    );

    window._qotto_pouch_provider = this;

    this.state = {
      docs: {},
      replicating: false,
      loading: true,
      readWriteLoading: false,
      rwSyncing: false,
      roSyncing: false,
      auth: null,
      error: null,
      warning: null,
      clearWarning: this.clearWarning,
      signout: this.signout,
      putDoc: this.putDoc,
    };
  }

  loadLocalDataInPouchDB = async () => {

    try {

      // flush and recreate a new database
      await this.database.destroy().catch(console.warn);
      this.createDatabases();

      // push local-data.json in pouchDB
      await dataFromLocalJson.reduce( async (previous, row) => {

        await previous;
        await this.database.put(row);
    
      }, Promise.resolve() );

      // get pouchDB docs and push them in local state
      const docs = await this.database
        .allDocs({ include_docs: true })
        .then(extractDocs)
        .then(reduceById);

      this.setState({ docs, loading: false });

    } catch (err) {
      console.error('pouchDB error', err)
    }
  
  }

  asyncSetState = async nextState => {
    await new Promise(resolve => this.setState(nextState, resolve));
  };

  onSyncError = async error => {
    console.warn("Sync error", error);
    if (error.status === 401) {
      await this.wipeLocalData();
      const isLoggedIn = Boolean(this.props.user);
      this.setState({
        replicating: isLoggedIn,
        rwSyncing: isLoggedIn,
        loading: true,
      });
      await this.init();
    }
  };

  isDBReplicated = async () => {
    if (!this.database) {
      return false;
    }
    try {
      await this.database.get("_local/DBIsReplicated");
      return true;
    } catch {
      return false;
    }
  };

  markDBAsReplicated = async () => {
    try {
      await this.database.get("_local/DBIsReplicated");
    } catch {
      await this.database.put({ _id: "_local/DBIsReplicated" });
    }
  };

  wipeAllRemainingPouches = async () => {
    if (!window || !window.indexedDB || !window.indexedDB.databases) {
      return;
    }
    const allDBs = await window.indexedDB.databases();
    let runningDBName;
    if (this.database) {
      runningDBName = `_pouch_${this.database.name}`;
    }
    allDBs
      .map(_ => _.name)
      .filter(_ => _.startsWith("_pouch_"))
      .filter(_ => _ !== runningDBName)
      .forEach(_ => window.indexedDB.deleteDatabase(_));
  };

  wipeLocalData = async () => {
    localStorage.removeItem(`_dbinit_${LOCAL_DB_NAME}`);
    if (this.database) {
      this.cancelSyncIfActive();
      if (this.changes) {
        this.changes.cancel();
        this.changes = null;
      }
      this.remoteDatabase = null;
      await this.database.destroy().catch(console.warn);
      this.database = null;
    }
    await this.wipeAllRemainingPouches();
    this.setState({
      docs: {},
      auth: null,
      loading: false,
      replicating: false,
      rwSyncing: false,
    });
  };

  signout = async () => {
    await this.wipeLocalData();
    this.setState({ loading: true });
    if (this.remoteDatabase) {
      await logOut();
    }
  };

  clearWarning = () => {
    this.setState({
      warning: null,
    });
  };

  putDoc = async rawDoc => {
    const { _memory_only, ...doc } = rawDoc;
    const { replicating } = this.state;

    let pouchInstance = this.database;
    if (replicating && this.remoteDatabase) {
      pouchInstance = this.remoteDatabase;
    }
    try {
      let translated_doc = translateDoc(doc);
      const { rev } = await pouchInstance.put(translated_doc);
      this.putDocsInMemory([
        {
          ...translated_doc,
          _rev: rev,
          _memory_only: true,
        },
      ]);
    } catch (error) {
      if (error instanceof DOMException) {
        console.warn("a DOMException occured on document PUT. Restarting Pouchdb instance...");
        this.database = new PouchDB(LOCAL_DB_NAME, conf.localDatabaseOpts);
        await this.database.put(doc);
      } else {
        throw error;
      }
    }
  };

  putDocsInMemory = updatedDocs => {
    const { docs } = this.state;
    this.setState({
      docs: {
        ...docs,
        ...reduceById(updatedDocs),
      },
    });
  };

  startReadOnlySync = async () => {
    const userId = this.props.user.uuid;
    const { update_seq } = await this.database.get("_local/readOnlyUpdateSeq");
    if (this.readOnlyChanges) {
      this.readOnlyChanges.cancel();
    }

    this.readOnlyChanges = readOnlyChanges(
      this.remoteDatabase,
      update_seq,
      userId,
      this.bufferedOnReadOnlyChanges
    );
    this.readOnlyChanges.promise.catch(async error => {
      if (window && window.navigator.onLine === false) {
        // we're offline. readOnlySync will restart once we're online.
        return;
      }
      console.error(error);
      console.warn("An error occured in changes feed. Retrying later...");
      await sleep(READ_ONLY_LIVE_CHANGES_RETRY_DELAY);
      return this.startReadOnlySync();
    });
  };

  startReadWriteSync = async () => {
    const userId = this.props.user.uuid;
    this.readWriteSync = this.database
      .sync(this.remoteDatabase, {
        pull: conf.readWriteSyncOpts({ userId, checkpoint: "target" }),
        push: conf.readWriteSyncOpts({ userId, checkpoint: "source" }),
      })
      .on("error", this.onSyncError)
      .on("paused", () => this.setState({ rwSyncing: false }))
      .on("active", () => this.setState({ rwSyncing: true }));
  };

  startSync = async () => {
    this.cancelSyncIfActive();
    if (this.isMainTab && this.database && this.remoteDatabase) {
      await this.startReadWriteSync();
      await this.startReadOnlySync();
    }
  };

  cancelSyncIfActive = () => {
    if (this.readWriteSync) {
      this.readWriteSync.cancel();
      this.readWriteSync = null;
    }
    if (this.readOnlyChanges) {
      this.readOnlyChanges.cancel();
      this.readOnlyChanges = null;
    }
  };

  onOnline = () => {
    this.startSync();
  };

  onOffline = () => {
    this.cancelSyncIfActive();
    this.triggerBackgroundSync();
  };

  onReadOnlyChanges = async changes => {
    this.setState({ roSyncing: true });
    await applyReadOnlyChanges(this.database, changes);
    this.setState({ roSyncing: false });
  };

  synchronize = async () => {
    this.changes = this.database
      .changes(conf.localChangesOpts)
      .on("change", change => {
        this.bufferedPutDocsInMemory(translateDoc(change.doc));
      })
      .on("error", async error => {
        console.warn("An error occured on local changes feed", error);
        this.cancelSyncIfActive();
        this.changes.cancel();
        await this.synchronize();
      });
    await this.startSync();
  };

  handleError = error => {
    this.setState({
      replicating: false,
      rwSyncing: false,
      loading: false,
      error,
    });
  };

  showWarning = warning => {
    this.setState({ warning });
  };

  createDatabases = () => {
    this.database = new PouchDB(LOCAL_DB_NAME, conf.localDatabaseOpts);
    this.remoteDatabase = new PouchDB(REMOTE_DB_PATH, conf.remoteDatabaseOpts);
  };

  initialFetch = async () => {
    const userId = this.props.user.uuid;
    const readOnlyUpdateSeq = await this.database.get("_local/readOnlyUpdateSeq").catch(_ => null);
    if (!this.state.loading && readOnlyUpdateSeq) {
      // docs are loaded & update_seq is already set
      return;
    }
    const [roView] = await Promise.all([
      initialReadOnlyView(this.remoteDatabase, userId).then(async roView => {

        const translated_docs = {};
        for (const [key, value] of Object.entries(roView.documentsStore)) {
          translated_docs[key] = translateDoc(value);
        }

        await this.asyncSetState(state => ({
          docs: {
            ...state.docs,
            ...translated_docs,
          },
          loading: false,
        }));
        return roView;
      }),
      initialReadWriteView(this.remoteDatabase, userId).then(async rwView => {
        await this.asyncSetState(state => ({
          docs: {
            ...state.docs,
            ...rwView.documentsStore,
          },
        }));
        return rwView;
      }),
    ]);
    if (!readOnlyUpdateSeq) {
      // TODO What if bulkDocs fails or is interupted? What about conflicts?
      await sleep(0);
      await this.database.bulkDocs(Object.values(roView.documentsStore));
      await this.database.put({
        _id: "_local/readOnlyUpdateSeq",
        update_seq: roView.updateSeq,
      });
    }
  };

  initialReplication = async () => {
    const userId = this.props.user.uuid;
    do {
      try {
        await this.initialFetch(this.database, this.remoteDatabase, userId);
        await readWriteReplication(this.database, this.remoteDatabase, userId);
        await this.markDBAsReplicated();
        this.setState({ replicating: false, rwSyncing: false });
        return;
      } catch (error) {
        console.warn(error);
        console.warn("Initial replication failed. Retrying later.");
        await sleep(INITIAL_REPLICATION_ERROR_DELAY);
      }
    } while (true);
  };

  waitForReplication = async () => {
    while (true) {
      await sleep(1000);
      if (await this.isDBReplicated()) {
        return true;
      }
    }
  };

  handleExternalReplication = async () => {
    await this.waitForReplication();
    this.setState({ replicating: false, rwSyncing: false });
  };

  triggerBackgroundSync = async () => {
    if (!ENABLE_BACKGROUND_SYNC || !navigator || !navigator.serviceWorker) {
      return;
    }
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
      await registration.sync.register("REMOTE_SYNC");
    }
  };

  init = async () => {

    this.createDatabases();

    // if a local-data.json exists, use it as local pouchDB data
    if (dataFromLocalJson) {
      await this.loadLocalDataInPouchDB();
      return null;
    }
    
    const { user } = this.props;

    await this.wipeAllRemainingPouches();
    const auth = await authenticate(user, this.database)
      .then(() => getAuthInfo(this.database))
      .catch(this.wipeLocalData);
    try {
      if (!auth) throw Error("FORBIDDEN");
      this.setState({ auth });
      const isReplicated = await this.isDBReplicated();
      if (this.isMainTab === null) {
        this.isMainTab = await requestIfMainTab();
      }
      if (!isReplicated) {
        this.setState({
          replicating: true,
          rwSyncing: true,
        });
        if (await isQuotaTooSmall()) throw Error("QUOTA_TOO_SMALL");
        if (this.isMainTab) {
          await this.initialReplication();
        } else {
          await this.handleExternalReplication();
        }
      }
      if (await isStorageUsageTooHigh()) {
        this.showWarning("USAGE_TOO_HIGH");
      }
      const docs = await this.database
        .allDocs({ include_docs: true })
        .then(extractDocs)
        .then(reduceById);
      this.setState({ docs, loading: false });
      if (!this.isMainTab) {
        this.watchMainTabChange();
      }
      await this.synchronize();
    } catch (e) {
      this.handleError(e.message);
    }
  };

  watchMainTabChange = () => {
    this.mainTabTimer = setInterval(async () => {
      this.isMainTab = await requestIfMainTab();
      if (this.isMainTab) {
        clearInterval(this.mainTabTimer);
        this.mainTabTimer = null;
        this.init();
      }
    }, WATCH_MAIN_TAB_TIMER);
  };

  async componentDidMount() {
    await this.init();
    if (window && window.navigator.onLine === false) {
      this.onOffline();
    }
    window.addEventListener("offline", this.onOffline);
    window.addEventListener("online", this.onOnline);
  }

  componentWillUnmount() {
    this.cancelSyncIfActive();
    window.removeEventListener("offline", this.onOffline);
    window.removeEventListener("online", this.onOnline);
  }

  render() {
    return (
      <PouchdbContext.Provider value={this.state}>{this.props.children}</PouchdbContext.Provider>
    );
  }
}

const ProviderWrapper = props => (
  <UserContext.Consumer>{({ user }) => <Provider user={user} {...props} />}</UserContext.Consumer>
);

export { ProviderWrapper as Provider };
