import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import { externalServiceType } from "types";

const ExternalButton = ({ service, args, ...props }) => {
  const Icon = service.icon;

  return (
    <Button href={service.uri(args)} style={{ color: service.color }} target="_blank" {...props}>
      <Icon /> {service.label}
    </Button>
  );
};

ExternalButton.propTypes = {
  service: externalServiceType.isRequired,
  args: PropTypes.object.isRequired,
};

export default ExternalButton;
