import React, { Fragment } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage as T } from "react-intl";

import { DateContext } from "contexts";
import { FormattedDuration } from "components";

const origin = {
  vertical: "bottom",
  horizontal: "right",
};

const stripSeconds = value => Math.round(value / 60) * 60;

const DateEvents = () => (
  <DateContext.Consumer>
    {({
      timezone,
      clientTimezone,
      offset,
      openTzToast,
      onTzToastClose,
      openOffsetToast,
      onOffsetToastClose,
    }) => (
      <Fragment>
        <Snackbar
          anchorOrigin={origin}
          open={openTzToast}
          message={<T id="tzSnackbar" values={{ timezone, clientTimezone }} />}
          action={
            <IconButton key="close" aria-label="Close" color="inherit" onClick={onTzToastClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Snackbar
          anchorOrigin={origin}
          open={openOffsetToast}
          message={
            <T
              id="offsetSnackbar"
              values={{
                offset: (
                  <Fragment>
                    <FormattedDuration
                      seconds={stripSeconds(Math.round(Math.abs(offset) / 1e3))}
                      precision={3}
                    />{" "}
                    <T id={offset > 0 ? "positiveOffset" : "negativeOffset"} />
                  </Fragment>
                ),
              }}
            />
          }
          action={
            <IconButton key="close" aria-label="Close" color="inherit" onClick={onOffsetToastClose}>
              <CloseIcon />
            </IconButton>
          }
        />
      </Fragment>
    )}
  </DateContext.Consumer>
);

export default DateEvents;
