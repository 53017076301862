import boxProperties from "data/installations/properties";

const CREATED_RELEVANCE_TRESHOLD = 1528467692787; // prior to this date (June 8 2018), installation date is not relevant.

export const isPriorToTreshold = timestamp => timestamp <= CREATED_RELEVANCE_TRESHOLD;

export const boxesToRowData = boxes => [
  Object.keys(boxProperties),
  ...boxes.map(box =>
    Object.values(boxProperties).map(property => {
      const { csvExport, getValue } = property;
      let value = getValue(box);
      if (csvExport) {
        value = csvExport(value);
      } else if (value === null || value === undefined) {
        value = "";
      }
      return value;
    })
  ),
];
