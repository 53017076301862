import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { FormattedMessage as T } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { Installation } from "./components";
import { CouchAuth } from "components";
import { InstallationsContext } from "contexts";

const styles = {
  noInstallationCard: {
    margin: "8px",
    padding: "16px 8px",
  },
};

const matchToInstallationId = ({ params }) => `${params.contract_code}-0000`;

const InstallationScene = ({ match, classes }) => (
  <CouchAuth>
    <InstallationsContext.Consumer>
      {({ installationsById }) => {
        const box = installationsById[matchToInstallationId(match)];
        return box ? (
          <Installation box={box} />
        ) : (
          <Card className={classes.noInstallationCard}>
            <CardContent>
              <T id="noInstallationFound" tagName="div" />
            </CardContent>
          </Card>
        );
      }}
    </InstallationsContext.Consumer>
  </CouchAuth>
);

InstallationScene.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      contract_code: PropTypes.string,
      bms_label: PropTypes.string,
    }),
  }).isRequired,
};

export default compose(withStyles(styles))(InstallationScene);

//Breadcrumb component
const Breadcrumb = ({ match }) => (
  <InstallationsContext.Consumer>
    {({ installationsById }) => {
      const box = installationsById[matchToInstallationId(match)];
      return box ? <T id="installationBreadcrumb" values={box.contract} /> : "Contrat";
    }}
  </InstallationsContext.Consumer>
);

export { Breadcrumb };
