import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { BoxStatusIcon, BoxRelativeExpiration, BoxTextStatus } from "components";
import { boxType } from "types";
import { FormattedMessage as T } from "react-intl";

const styles = {
  icon: {
    verticalAlign: "middle",
  },
};

const BoxStatus = ({ box, icon, classes }) => (
  <span>
    {icon && (
      <Fragment>
        <BoxStatusIcon className={classes.icon} box={box} />{" "}
      </Fragment>
    )}
    {box.contract.fully_paid ?
      <Fragment>
        <T id={"endOfContract"}/>{" "}
        <BoxRelativeExpiration expiration={box.expiration_timestamp} />
      </Fragment>
      :
     box.expiration_timestamp && (
      <Fragment>
        <BoxTextStatus expiration={box.expiration_timestamp} />{" "}
        <BoxRelativeExpiration expiration={box.expiration_timestamp} />
      </Fragment>
    )}
  </span>
);

BoxStatus.propTypes = {
  box: boxType.isRequired,
  icon: PropTypes.bool,
};

export default withStyles(styles)(BoxStatus);
