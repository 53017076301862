import React from "react";
import { FormattedMessage as T } from "react-intl";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { HelpDialog } from "components";

const styles = {
  helpButton: {
    padding: "6px",
  },
};

class BreadcrumbHelp extends React.PureComponent {
  state = {
    helpDialogOpen: false,
  };

  toggleHelpDialog = () => {
    const { helpDialogOpen } = this.state;
    this.setState({
      helpDialogOpen: !helpDialogOpen,
    });
  };

  render() {
    const { route, classes } = this.props;
    const { helpDialogOpen } = this.state;

    if (!route || !route.showHelp) {
      return null;
    }

    return (
      <>
        <HelpDialog open={helpDialogOpen} onClose={this.toggleHelpDialog} route={route} />
        <Tooltip title={<T id="openHelp" />}>
          <IconButton
            onClick={this.toggleHelpDialog}
            className={classes.helpButton}
            aria-label="help"
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

export default withStyles(styles)(BreadcrumbHelp);
