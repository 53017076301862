import React, { useState } from "react";
import classNames from "classnames";
import { FormattedMessage as T } from "react-intl";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import { PouchdbContext } from "contexts";
import { CustomPrimaryColor } from "components";

import allFlags, { FlagDefinition } from "flags";

const styles = createStyles({
  root: {},
  button: {
    margin: "2px",
    padding: "2px 4px",
  },
  checkbox: {
    padding: 0,
  },
  checkedCheckbox: {
    color: "white !important",
  },
});

const buildFlagId = (flag: FlagDefinition, contractCode: string): string =>
  `urn.qotto.flag:installation:${contractCode}:${flag.name}`;

const buildFlagDocument = (flag: FlagDefinition, contractCode: string): any => ({
  _id: buildFlagId(flag, contractCode),
  type: "flag",
});

interface InstallationFlagProps extends WithStyles<typeof styles> {
  contractCode: string;
  flag: FlagDefinition;
  flagDoc: any;
  putDoc: (doc: any) => void;
}

const InstallationFlag = (props: InstallationFlagProps) => {
  const { putDoc, flag, flagDoc, contractCode, classes } = props;

  const [isUpdating, setIsUpdating] = useState(false);

  const isFlagEnabled = Boolean(flagDoc && !flagDoc._deleted);

  const updateDisabled = isUpdating || (flagDoc && flagDoc._memory_only);

  const handleFlagClick = async () => {
    if (!contractCode || !putDoc || updateDisabled) return;
    setIsUpdating(true);
    if (isFlagEnabled) {
      //flag is enabled, let's disable it
      await putDoc({
        ...flagDoc,
        _deleted: true,
      });
    } else {
      //flag is disabled, let's enable it
      await putDoc(buildFlagDocument(flag, contractCode));
    }
    setIsUpdating(false);
  };

  return (
    <CustomPrimaryColor color={flag.color}>
      <Tooltip title={<T id={`flag.${flag.name}.description`} />}>
        <Button
          key={flag.name}
          size="small"
          color="primary"
          variant={isFlagEnabled ? "contained" : "text"}
          onClick={handleFlagClick}
          disabled={updateDisabled}
          className={classes.button}
        >
          {updateDisabled ? (
            <CircularProgress size={24} />
          ) : (
            <Checkbox
              checked={isFlagEnabled}
              className={classNames(classes.checkbox, {
                [classes.checkedCheckbox]: isFlagEnabled,
              })}
            />
          )}
          &nbsp;
          <flag.icon />
          &nbsp;
          <T id={`flag.${flag.name}`} />
          &nbsp;
        </Button>
      </Tooltip>
    </CustomPrimaryColor>
  );
};

interface InstallationFlagsProps extends WithStyles<typeof styles> {
  contractCode: string;
  docsByFlagName: { [key: string]: any };
  putDoc: (doc: any) => void;
}

const InstallationFlags = (props: InstallationFlagsProps) => (
  <span className={props.classes.root}>
    {allFlags.map((flag: FlagDefinition) => (
      <InstallationFlag
        key={flag.name}
        flag={flag}
        contractCode={props.contractCode}
        flagDoc={props.docsByFlagName[flag.name]}
        putDoc={props.putDoc}
        classes={props.classes}
      />
    ))}
  </span>
);

const InstallationFlagsWrapper = (props: InstallationFlagsProps) => (
  <PouchdbContext.Consumer>
    {({ docs, putDoc }: { docs: any; putDoc: (doc: any) => void }) => {
      const docsByFlagName = allFlags.reduce(
        (acc, flag) => ({
          ...acc,
          [flag.name]: docs[buildFlagId(flag, props.contractCode)],
        }),
        {}
      );
      return <InstallationFlags {...props} putDoc={putDoc} docsByFlagName={docsByFlagName} />;
    }}
  </PouchdbContext.Consumer>
);

export default withStyles(styles)(InstallationFlagsWrapper);
