import React from "react";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "typeface-roboto";

import theme from "./mui-theme";
import { Layout, ScrollToTop, I18nProvider } from "scenes";
import "./app.css";
import { checkEnvKeys } from "./env";
import {
  DateProvider,
  SWProvider,
  PouchdbProvider,
  InstallationsProvider,
  CountryProvider,
  UserProvider,
} from "contexts";

checkEnvKeys();

const App = () => (
  <UserProvider>
    <DateProvider>
      <I18nProvider>
        <BrowserRouter>
          <CountryProvider>
            <SWProvider>
              <PouchdbProvider>
                <InstallationsProvider>
                  <MuiThemeProvider theme={theme}>
                    <ScrollToTop>
                      <Layout />
                    </ScrollToTop>
                  </MuiThemeProvider>
                </InstallationsProvider>
              </PouchdbProvider>
            </SWProvider>
          </CountryProvider>
        </BrowserRouter>
      </I18nProvider>
    </DateProvider>
  </UserProvider>
);

export default App;
