import React from "react";
import { Redirect } from "react-router-dom";

import { addToPath } from "countries";
import { CountryContext } from "contexts";

const LocalRedirect = ({ to, ...props }) => (
  <CountryContext.Consumer>
    {country => <Redirect to={addToPath(to, country)} {...props} />}
  </CountryContext.Consumer>
);

export default LocalRedirect;
