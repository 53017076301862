import React from "react";

import { FormattedRelative } from "components";
import { DateContext } from "contexts";

const OffsetFormattedRelative = ({ value, ...props }) => (
  <DateContext.Consumer>
    {({ offset }) => <FormattedRelative {...props} value={new Date(value.getTime() + offset)} />}
  </DateContext.Consumer>
);

export default OffsetFormattedRelative;
