import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FormattedMessage as T } from "react-intl";

import { externalServiceType } from "types";

const styles = {
  iframe: {
    border: "none",
    width: "100%",
    height: "1000px",
  },
};

class ZohoTab extends React.PureComponent {
  state = {
    editMode: false,
  };

  toggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  render() {
    const { zohoService, zohoArgs, classes } = this.props;
    const { editMode } = this.state;

    return (
      <div>
        {editMode ? (
          <Button onClick={this.toggleEditMode} size="small">
            <ArrowBackIcon /> <T id="zohoViewMode" />
          </Button>
        ) : (
          <Button onClick={this.toggleEditMode} size="small" variant="outlined" color="primary">
            <EditIcon /> <T id="zohoEditMode" />
          </Button>
        )}
        <iframe
          src={zohoService.iframe({
            ...zohoArgs,
            edit: editMode,
          })}
          className={classes.iframe}
          title={zohoService.label}
          frameBorder="0"
        />
      </div>
    );
  }
}

ZohoTab.propTypes = {
  zohoService: externalServiceType.isRequired,
  zohoArgs: PropTypes.object.isRequired,
};

export default withStyles(styles)(ZohoTab);
