import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";

import { BoxStatusIcon, BoxTitle, BoxSubtitle, LocalLink } from "components";
import { boxType } from "types";

const styles = {};

const BoxListItem = ({ box }) => (
  <ListItem button component={LocalLink} to={`/${box.contract.code}`} divider>
    <BoxStatusIcon box={box} />
    <ListItemText primary={<BoxTitle box={box} />} secondary={<BoxSubtitle box={box} />} />
  </ListItem>
);

const BoxList = ({ boxes, classes }) => (
  <List>
    {boxes.map(box => (
      <BoxListItem key={box.installation_id} box={box} />
    ))}
  </List>
);

BoxList.propTypes = {
  boxes: PropTypes.arrayOf(boxType).isRequired,
};

export default withStyles(styles)(BoxList);
