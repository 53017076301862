import NetworkStrengthOffIcon from "mdi-material-ui/NetworkStrengthOff";
import AlertIcon from "mdi-material-ui/Alert";
import HandLeftIcon from "mdi-material-ui/HandLeft";
import CrownIcon from "mdi-material-ui/Crown";
import RadioboxMarked from "mdi-material-ui/RadioboxMarked";
import { purple, orange, red, amber, blue } from "@material-ui/core/colors";

export interface FlagDefinition {
  name: string;
  icon: any;
  color: any;
}

const flags: FlagDefinition[] = [
  {
    name: "no-gsm",
    icon: NetworkStrengthOffIcon,
    color: purple,
  },
  {
    name: "cheating",
    icon: AlertIcon,
    color: orange,
  },
  {
    name: "uninstall-refused",
    icon: HandLeftIcon,
    color: red,
  },
  {
    name: "vip",
    icon: CrownIcon,
    color: amber,
  },
  {
    name: "push-button",
    icon: RadioboxMarked,
    color: blue,
  },
];

export default flags;
