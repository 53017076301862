import React from "react";
import { Route, Switch } from "react-router-dom";

import { InstallationToHome, DeviceOldIdToNewId } from "./components";
import countries from "countries";

const countryEnum = countries.map(c => c.label).join("|");

const TopLevelRedirect = ({ children }) => (
  <Switch>
    <Route exact path="/installation/:contract_code-:bms_label" component={InstallationToHome} />
    <Route exact path="/installation" component={InstallationToHome} />
    <Route
      exact
      path={`/:country(${countryEnum})?/:map(map)?/:contract_code-:bms_label`}
      component={DeviceOldIdToNewId}
    />
    <Route render={() => children} />
  </Switch>
);

export default TopLevelRedirect;
