import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage as T } from "react-intl";

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE;
const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;
const DAYS_IN_A_YEAR = 365.2425;
const MONTHS_IN_A_YEAR = 12;
const SECONDS_IN_A_YEAR = Math.round(DAYS_IN_A_YEAR * SECONDS_IN_A_DAY);
const SECONDS_IN_A_MONTH = Math.round(SECONDS_IN_A_YEAR / MONTHS_IN_A_YEAR);

const DURATIONS = [
  { type: "year", seconds: SECONDS_IN_A_YEAR },
  { type: "month", seconds: SECONDS_IN_A_MONTH },
  { type: "day", seconds: SECONDS_IN_A_DAY },
  { type: "hour", seconds: SECONDS_IN_AN_HOUR },
  { type: "minute", seconds: SECONDS_IN_A_MINUTE },
  { type: "second", seconds: 1 },
];

const getDurationLegs = (seconds, precision) => {
  const legs = [];
  let _seconds = seconds;

  // we first populate the legs
  DURATIONS.forEach(duration => {
    const value = Math.floor(_seconds / duration.seconds);
    legs.push({ ...duration, value });
    _seconds -= value * duration.seconds;
  });

  // we then remove every unneeded leg by giving its value to the previous leg
  for (let i = legs.length - 1; i > 0; --i) {
    const leg = legs[i];
    const prevLeg = legs[i - 1];
    if (leg.seconds * leg.value >= prevLeg.seconds) {
      const prevLegAddedValue = Math.floor((leg.seconds * leg.value) / prevLeg.seconds);
      prevLeg.value += prevLegAddedValue;
      leg.value -= Math.floor((prevLegAddedValue * prevLeg.seconds) / leg.seconds);
    }
    const precisionBefore = legs.slice(0, i).filter(leg => leg.value > 0).length;
    if (precisionBefore >= precision) {
      prevLeg.value += Math.round((leg.seconds * leg.value) / prevLeg.seconds);
      leg.value = 0;
    }
  }

  return legs.filter(leg => leg.value > 0);
};

const FormattedDuration = ({ seconds, precision }) => {
  const legs = getDurationLegs(seconds, precision);

  return (
    <>
      {legs.map((leg, index) => (
        <Fragment key={leg.type}>
          <T id={`formatted-duration.${leg.type}`} values={leg} />
          {index !== legs.length - 1 && <>, </>}
        </Fragment>
      ))}
    </>
  );
};

FormattedDuration.defaultProps = {
  precision: 1,
};

FormattedDuration.propTypes = {
  seconds: PropTypes.number.isRequired,
  precision: PropTypes.number,
};

export default FormattedDuration;
