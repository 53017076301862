import React from "react";
import MapIcon from "@material-ui/icons/Map";
import RouterIcon from "@material-ui/icons/Router";

import Installations from "scenes/Installations";
import Installation, { Breadcrumb as InstallationBreadcrumb } from "scenes/Installation";
import Map from "scenes/Map";
import NoMatch from "scenes/NoMatch";
import countries from "countries";

const countryLabels = countries.map(c => c.label);

export default {
  path: `/:country(${countryLabels.join("|")})?`,
  label: "installations",
  component: Installations,
  noMatchComponent: NoMatch,
  sharable: true,
  menu: {
    icon: <RouterIcon />,
    exact: true,
  },
  showHelp: true,
  children: [
    {
      path: "/map",
      label: "map",
      component: Map,
      sharable: true,
      fullscreen: true,
      menu: {
        icon: <MapIcon />,
      },
      children: [
        {
          path: "/:contract_code(\\d+)",
          label: "installation",
          component: Installation,
          breadcrumb: InstallationBreadcrumb,
          sharable: true,
          disableCountrySwitch: true,
        },
      ],
    },
    {
      path: "/:contract_code(\\d+)",
      label: "installation",
      component: Installation,
      breadcrumb: InstallationBreadcrumb,
      sharable: true,
      disableCountrySwitch: true,
    },
  ],
};
