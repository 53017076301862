import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage as T } from "react-intl";

const BoxTextStatus = ({ expiration, determinant }) => (
  <span>
    <T id={expiration > Date.now() ? "runningInstallationPrefix" : "expiredInstallationPrefix"} />
    {determinant && (
      <Fragment>
        &nbsp;
        <T id={"expirationDeterminant"} />
      </Fragment>
    )}
  </span>
);

BoxTextStatus.propTypes = {
  expiration: PropTypes.number.isRequired,
  determinant: PropTypes.bool,
};

export default BoxTextStatus;
