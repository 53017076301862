import React from "react";
import { LocalRedirect } from "components";

const InstallationToHome = ({ match }) => {
  const { contract_code, bms_label } = match.params;
  if (contract_code && bms_label) {
    return <LocalRedirect to={`/${contract_code}`} />;
  }
  return <LocalRedirect to="/" />;
};

export default InstallationToHome;
