import React from "react";

import { boxType } from "types";

const BoxConsumptionPercentage = ({ box }) => {
  const energy = box.avg_power_7days;
  return (
    <span>
      {energy && box.avg_power_7days.production !== 0 && (
        <React.Fragment>
          {Math.round((box.avg_power_7days.consumption * 100) / box.avg_power_7days.production)}%
        </React.Fragment>
      )}
    </span>
  );
};

BoxConsumptionPercentage.propTypes = {
  box: boxType.isRequired,
};

export default BoxConsumptionPercentage;
