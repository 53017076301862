import React from "react";
import PropTypes from "prop-types";
import Badge from "@material-ui/core/Badge";

const CustomBadge = ({ disabled, ...props }) => (disabled ? props.children : <Badge {...props} />);

CustomBadge.propTypes = {
  disabled: PropTypes.bool,
};

export default CustomBadge;
