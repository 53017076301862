import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  progressWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const LoadingButton = ({ loading, children, classes, ...props }) => {
  return (
    <Button {...props}>
      {children}
      {loading && (
        <div className={classes.progressWrapper}>
          <CircularProgress size={24} />
        </div>
      )}
    </Button>
  );
};

export default withStyles(styles)(LoadingButton);
