import { PureComponent } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends PureComponent {
  componentDidUpdate(prevProps) {
    const prevPathname = prevProps.location.pathname;
    const { pathname } = this.props.location;
    if (window && pathname !== prevPathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
