const requiredEnvKeys = [
  "REACT_APP_COUCHDB_SERVER",
  "REACT_APP_COUCHDB_DB_NAME",
  "REACT_APP_BACKEND_API_ENDPOINT",
  "REACT_APP_JWT_PUBLIC_KEY",
  "REACT_APP_LOGIN_API_URL",
  "REACT_APP_LOGIN_FRONTEND_URL",
  "REACT_APP_TOKEN_COOKIE_NAME",
  "REACT_APP_COOKIE_DOMAIN",
];

export const checkEnvKeys = () => {
  const testEnv = process.env.NODE_ENV === "test";
  const missingKeys = requiredEnvKeys.filter(key => !process.env[key]);

  if (missingKeys.length !== 0) {
    throw new Error(
      "Missing env keys :\n" +
        missingKeys.join("\n") +
        `\nPlease update .env${testEnv ? ".test" : ""}.local file with the corresponding keys`
    );
  }
};
