import React from "react";
import uuidv4 from "uuid/v4";
import { FormattedMessage as T } from "react-intl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/core/styles";

import { LoadingButton } from "components";

import { DateContext, InstallationsContext, PouchdbContext } from "contexts";

const styles = {
  form: {
    position: "relative",
    marginTop: "8px",
    marginBottom: "24px",
  },
  inputLine: {
    display: "flex",
    alignItems: "flex-end",
  },
  datetimeInput: {
    minWidth: "138px",
  },
  submitLine: {
    marginTop: "8px",
  },
};

const stripSeconds = date => {
  const res = new Date(date.getTime());
  res.setSeconds(0, 0);
  return res;
};

const toInput = timestamp => {
  if (!timestamp) {
    return "";
  }
  let date = new Date(timestamp);
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1e3);
  date = stripSeconds(date);
  return date.toISOString().split("Z")[0];
};

const fromInput = isoString => {
  const date = new Date(isoString);

  return date.getTime() || null;
};

class LastClientCall extends React.PureComponent {
  state = {};

  static getDerivedStateFromProps = (props, state) => {
    const defaultCallDate = props.client.last_call;
    if (defaultCallDate !== state.defaultCallDate) {
      return {
        defaultCallDate,
        date_called: defaultCallDate,
        loading: false,
      };
    }
    return state;
  };

  handleChange = e => {
    this.setState({
      date_called: fromInput(e.target.value),
    });
  };

  cancelChanges = () => {
    this.setState({
      date_called: this.state.defaultCallDate,
    });
  };

  setToNow = () => {
    const now = this.props.getDate();
    this.setState({
      date_called: now.getTime(),
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { client, lastCallDoc, putDoc } = this.props;
    const { date_called, defaultCallDate } = this.state;
    const hasChanged = date_called !== defaultCallDate;
    if (!hasChanged) {
      return false;
    }
    let newDoc;
    if (lastCallDoc) {
      newDoc = {
        ...lastCallDoc,
        date_called,
      };
    } else {
      newDoc = {
        _id: `urn.qotto.last_client_call:${uuidv4()}`,
        type: "last_client_call",
        client_id: client.zoho_id,
        date_called,
      };
    }
    this.setState({ loading: true });
    putDoc(newDoc);
    return false;
  };

  render() {
    const { classes } = this.props;
    const { date_called, defaultCallDate, loading } = this.state;

    const hasChanged = date_called !== defaultCallDate;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <div className={classes.inputLine}>
          <TextField
            label={<T id="lastCallDate" />}
            name="lastCallDate"
            type="datetime-local"
            value={toInput(date_called)}
            onChange={this.handleChange}
            className={classes.datetimeInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button size="small" onClick={this.setToNow}>
            <T id="now" />
          </Button>
        </div>
        {hasChanged && (
          <div className={classes.submitLine}>
            <LoadingButton
              type="submit"
              size="small"
              loading={loading}
              disabled={loading}
              className={classes.saveButton}
              variant="contained"
              color="primary"
            >
              <SaveIcon /> <T id="save" />
            </LoadingButton>{" "}
            <Button size="small" disabled={loading} onClick={this.cancelChanges}>
              <T id="cancel" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

LastClientCall.propTypes = {
  client: PropTypes.shape({
    zoho_id: PropTypes.string.isRequired,
  }),
};

const LastClientCallWrapper = props => (
  <DateContext.Consumer>
    {({ getDate }) => (
      <InstallationsContext.Consumer>
        {({ lastClientCalls }) => (
          <PouchdbContext.Consumer>
            {({ putDoc }) => (
              <LastClientCall
                putDoc={putDoc}
                lastCallDoc={lastClientCalls[props.client.zoho_id]}
                getDate={getDate}
                {...props}
              />
            )}
          </PouchdbContext.Consumer>
        )}
      </InstallationsContext.Consumer>
    )}
  </DateContext.Consumer>
);

export default withStyles(styles)(LastClientCallWrapper);
