import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { DateContext, UserContext } from "contexts";
import { TimezoneSelect, LanguageSelect } from "components";
import { availableLanguages } from "i18n";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: "4px 0",
  },
};

const DrawerFooter = ({ classes }) => (
  <div className={classes.root}>
    <UserContext.Consumer>
      {({ preferences, setPreference }) => (
        <LanguageSelect
          language={preferences.language}
          setLanguage={setPreference("language")}
          availableLanguages={availableLanguages}
        />
      )}
    </UserContext.Consumer>
    <div className={classes.divider} />
    <DateContext.Consumer>
      {({ timezone, setTimezone, getDate }) => (
        <TimezoneSelect timezone={timezone} setTimezone={setTimezone} getDate={getDate} />
      )}
    </DateContext.Consumer>
  </div>
);

export default withStyles(styles)(DrawerFooter);
