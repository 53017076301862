import { Icon, divIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";

export const initLeaflet = () => {
  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
  });
};

export const geolocationIcon = divIcon({
  className: "",
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  html: `
        <svg width="20" height="20">
            <circle cx="10" cy="10" r="8" fill="cornflowerblue" opacity="0.4" />
            <circle cx="10" cy="10" r="6" fill="cornflowerblue" opacity="1" />
        </svg>
    `,
});
