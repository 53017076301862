import React from "react";
import { LocalRedirect, CouchAuth } from "components";
import { InstallationsContext } from "contexts";

const DeviceToInstallation = ({ match }) => (
  <CouchAuth>
    <InstallationsContext.Consumer>
      {({ installations }) => {
        const installation = installations.find(
          _ => match.params.id === _.sc_serial || match.params.id === _.installation_id
        );
        if (!installation) {
          return null;
        }
        return <LocalRedirect to={`/${installation.contract.code}`} />;
      }}
    </InstallationsContext.Consumer>
  </CouchAuth>
);

export default DeviceToInstallation;
