import React from "react";
import { IntlProvider } from "react-intl";

import getMessages from "i18n";
import { DateContext, UserContext } from "contexts";

const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;

const I18nProvider = ({ children }) => (
  <DateContext.Consumer>
    {({ timezone }) => (
      <UserContext.Consumer>
        {({ preferences }) => (
          <IntlProvider
            key={timezone + preferences.language}
            locale={preferences.language}
            timeZone={timezone}
            defaultLocale={DEFAULT_LOCALE}
            messages={getMessages(preferences.language)}
          >
            {children}
          </IntlProvider>
        )}
      </UserContext.Consumer>
    )}
  </DateContext.Consumer>
);

export default I18nProvider;
