import { createMuiTheme } from "@material-ui/core/styles";
import { green, amber } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: green["400"],
      main: green["600"],
      dark: green["800"],
    },
    secondary: {
      light: amber["400"],
      main: amber["600"],
      dark: amber["800"],
    },
    background: {
      default: "#eee",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export default theme;
